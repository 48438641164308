import { ContractorPortalLogout, ContractorPortalSetDriver } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { ContractorPortalDriverState } from './types'

const getInitialState = (): ContractorPortalDriverState => ({
  contractorPortalDriver: null,
  isContractorPortalDriverInitialized: false,
})

export const contractorPortalDriverReducer = (
  state: ContractorPortalDriverState = getInitialState(),
  action: Action<any>,
): ContractorPortalDriverState => {
  switch (action.type) {
    case ContractorPortalSetDriver.type.SUCCESS:
      return {
        ...state,
        isContractorPortalDriverInitialized: true,
        contractorPortalDriver: action.payload,
      }
    case ContractorPortalLogout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
