import { states } from './states'

export enum DocusignFieldTabLabel {
  fullName = 'fullName',
  email = 'email',
  address = 'address',
  city = 'city',
  state = 'state',
  zipcode = 'zipcode',
  cityStateZipcode = 'cityStateZipcode',
  phoneNumber = 'phoneNumber',
  phoneNumberHome = 'phoneNumberHome',
  driverLicenseNumber = 'driverLicenseNumber',
  ssn = 'ssn',
  tin = 'tin',
  fein = 'fein',
  dob = 'dob',
  beneficiary = 'beneficiary',
  beneficiaryRelationship = 'beneficiaryRelationship',
  insuranceCarrier = 'insuranceCarrier',
  otherInsurancePlan = 'otherInsurancePlan',
  eSignatureAck = 'eSignatureAck',
  businessName = 'businessName',
  ein = 'ein',
  vehicleYear = 'vehicleYear',
  vehicleMake = 'vehicleMake',
  vehicleModel = 'vehicleModel',
  vehicleVIN = 'vehicleVIN',
  vehicleLicensePlate = 'vehicleLicensePlate',
  vehicleYear2 = 'vehicleYear2',
  vehicleMake2 = 'vehicleMake2',
  vehicleModel2 = 'vehicleModel2',
  vehicleVIN2 = 'vehicleVIN2',
  vehicleLicensePlate2 = 'vehicleLicensePlate2',
  taxClassificationType = 'taxClassificationType',
  llcType = 'llcType',
  exemptPayeeCode = 'exemptPayeeCode',
  exemptionFatcaCode = 'exemptionFatcaCode',
  mmsOregonAddress = 'mmsOregonAddress',
  otherForeignPartners = 'otherForeignPartners',
  otherTaxClassification = 'otherTaxClassification',
}

export const DocusignFieldNames = {
  [DocusignFieldTabLabel.phoneNumber]: 'Phone number',
  [DocusignFieldTabLabel.phoneNumberHome]: 'Home Phone number',
  [DocusignFieldTabLabel.email]: 'Email address',
  [DocusignFieldTabLabel.fullName]: 'Full name',
  [DocusignFieldTabLabel.address]: 'Address',
  [DocusignFieldTabLabel.city]: 'City',
  [DocusignFieldTabLabel.state]: 'State',
  [DocusignFieldTabLabel.zipcode]: 'ZIP code',
  [DocusignFieldTabLabel.cityStateZipcode]: 'City, state, and ZIP code',
  [DocusignFieldTabLabel.ssn]: 'Social security number',
  [DocusignFieldTabLabel.tin]: 'Taxpayer ID number',
  [DocusignFieldTabLabel.fein]: 'Federal employer ID number',
  [DocusignFieldTabLabel.dob]: 'Your Date of Birth',
  [DocusignFieldTabLabel.beneficiary]:
    'Beneficiary (This info will be used for your Occupational Accident Insurance)',
  [DocusignFieldTabLabel.beneficiaryRelationship]:
    'Relationship to Beneficiary (This info will be used for your Occupational Accident Insurance)',
  [DocusignFieldTabLabel.insuranceCarrier]: 'If Yes, name of insurance carrier',
  [DocusignFieldTabLabel.otherInsurancePlan]:
    'Are you covered under any other medical and/or disability insurance plan?',
  [DocusignFieldTabLabel.eSignatureAck]: 'Electronic signature acknowledgement',
  [DocusignFieldTabLabel.businessName]: 'Business entity name',
  [DocusignFieldTabLabel.ein]: 'Employer Identification Number (EIN)',
  [DocusignFieldTabLabel.vehicleYear]: 'Vehicle Year',
  [DocusignFieldTabLabel.vehicleMake]: 'Vehicle Make',
  [DocusignFieldTabLabel.vehicleModel]: 'Vehicle Model',
  [DocusignFieldTabLabel.vehicleVIN]: 'Vehicle VIN',
  [DocusignFieldTabLabel.vehicleLicensePlate]: 'Vehicle License Plate',
  [DocusignFieldTabLabel.vehicleYear2]: 'Additional Vehicle Year',
  [DocusignFieldTabLabel.vehicleMake2]: 'Additional Vehicle Make',
  [DocusignFieldTabLabel.vehicleModel2]: 'Additional Vehicle Model',
  [DocusignFieldTabLabel.vehicleVIN2]: 'Additional Vehicle VIN',
  [DocusignFieldTabLabel.vehicleLicensePlate2]: 'Additional Vehicle License Plate',
  [DocusignFieldTabLabel.taxClassificationType]:
    'Tax Classification (This info will be used for the W9 form)',
  [DocusignFieldTabLabel.llcType]: 'LLC (If selected, you must select one of the following)',
  [DocusignFieldTabLabel.exemptPayeeCode]:
    'Exempt payee code, if any (This info will be used for the W9 form and is optional, can leave blank)',
  [DocusignFieldTabLabel.exemptionFatcaCode]:
    'Exemption from Foreign Account Tax Compliance Act (FATCA) reporting code, if any  (This info will be used for the W9 form and is optional, can leave blank)',
  [DocusignFieldTabLabel.mmsOregonAddress]: '',
  [DocusignFieldTabLabel.driverLicenseNumber]: "Driver's License Number",
  [DocusignFieldTabLabel.otherForeignPartners]:
    'If you selected “Partnership” or “Trust/estate,” or checked “LLC” and entered “P” as its tax classification above, AND you are providing this form to a partnership, trust, or estate in which you have an ownership interest, check this box if you have any foreign partners, owners, or beneficiaries. (otherwise leave unchecked)',
  [DocusignFieldTabLabel.otherTaxClassification]: 'Other Tax Classification',
}

export const DocusignFieldPlaceholders = {
  [DocusignFieldTabLabel.phoneNumber]: 'Enter phone number',
  [DocusignFieldTabLabel.phoneNumberHome]: 'Enter home phone number',
  [DocusignFieldTabLabel.email]: 'Enter email address',
  [DocusignFieldTabLabel.fullName]: 'Enter full name',
  [DocusignFieldTabLabel.address]: 'Enter address (number, street, and apt. or suite no.)',
  [DocusignFieldTabLabel.city]: 'Enter city',
  [DocusignFieldTabLabel.state]: 'Enter state',
  [DocusignFieldTabLabel.zipcode]: 'Enter ZIP code',
  [DocusignFieldTabLabel.cityStateZipcode]: 'Enter city, state, and ZIP code',
  [DocusignFieldTabLabel.ssn]: 'Enter social security number',
  [DocusignFieldTabLabel.tin]: 'Enter taxpayer ID number',
  [DocusignFieldTabLabel.fein]: 'Enter federal employer ID number',
  [DocusignFieldTabLabel.dob]: 'Enter your date of birth',
  [DocusignFieldTabLabel.beneficiary]: 'Enter beneficiary',
  [DocusignFieldTabLabel.beneficiaryRelationship]: 'Enter relationship to beneficiary',
  [DocusignFieldTabLabel.insuranceCarrier]: 'Enter insurance carrier',
  [DocusignFieldTabLabel.otherInsurancePlan]: '',
  [DocusignFieldTabLabel.eSignatureAck]: '',
  [DocusignFieldTabLabel.businessName]: 'Enter business entity name',
  [DocusignFieldTabLabel.ein]: 'Enter employer identification number',
  [DocusignFieldTabLabel.vehicleYear]: 'Enter vehicle year',
  [DocusignFieldTabLabel.vehicleMake]: 'Enter vehicle make',
  [DocusignFieldTabLabel.vehicleModel]: 'Enter vehicle model',
  [DocusignFieldTabLabel.vehicleVIN]: 'Enter vehicle VIN',
  [DocusignFieldTabLabel.vehicleLicensePlate]: 'Enter vehicle license plate',
  [DocusignFieldTabLabel.vehicleYear2]: 'Enter additional vehicle year',
  [DocusignFieldTabLabel.vehicleMake2]: 'Enter additional vehicle make',
  [DocusignFieldTabLabel.vehicleModel2]: 'Enter additional vehicle model',
  [DocusignFieldTabLabel.vehicleVIN2]: 'Enter additional vehicle VIN',
  [DocusignFieldTabLabel.vehicleLicensePlate2]: 'Enter additional vehicle license plate',
  [DocusignFieldTabLabel.taxClassificationType]: '',
  [DocusignFieldTabLabel.llcType]: '',
  [DocusignFieldTabLabel.exemptPayeeCode]: 'Exempt payee code (if any)',
  [DocusignFieldTabLabel.exemptionFatcaCode]:
    'Exemption from Foreign Account Tax Compliance Act (FATCA) reporting code (if any)',
  [DocusignFieldTabLabel.mmsOregonAddress]: '',
  [DocusignFieldTabLabel.driverLicenseNumber]: 'Enter driver license number',
  [DocusignFieldTabLabel.otherForeignPartners]: '',
  [DocusignFieldTabLabel.otherTaxClassification]: 'Enter other tax classification',
}

export const llcTypeOptions = [
  { value: 'C', label: 'C corporation' },
  { value: 'S', label: 'S corporation' },
  { value: 'P', label: 'Partnership' },
]

export enum TaxClassificationType {
  Individual = 'individual',
  CCorp = 'cCorp',
  SCorp = 'sCorp',
  Partnership = 'partnership',
  Trust = 'trust',
  Llc = 'llc',
  Other = 'other',
}

export const DocusignFieldSelectOptions: any = {
  [DocusignFieldTabLabel.state]: states,
  [DocusignFieldTabLabel.llcType]: llcTypeOptions,
}

export const DocusignTaxClassificationLabelMap: { [key in TaxClassificationType]: string } = {
  [TaxClassificationType.Individual]: 'Individual/sole proprietor',
  [TaxClassificationType.CCorp]: 'C corporation',
  [TaxClassificationType.SCorp]: 'S corporation',
  [TaxClassificationType.Partnership]: 'Partnership',
  [TaxClassificationType.Trust]: 'Trust/estate',
  [TaxClassificationType.Llc]: 'LLC',
  [TaxClassificationType.Other]: 'Other',
}
