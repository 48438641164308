import { ContractorPortalLogout, ContractorPortalFetchPartners } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { ContractorPortalPartnersState } from './types'

const getInitialState = (): ContractorPortalPartnersState => ({
  contractorPortalPartners: [],
})

export const contractorPortalPartnersReducer = (
  state: ContractorPortalPartnersState = getInitialState(),
  action: Action<any>,
): ContractorPortalPartnersState => {
  switch (action.type) {
    case ContractorPortalFetchPartners.type.SUCCESS:
      return {
        ...state,
        contractorPortalPartners: action.payload,
      }
    case ContractorPortalLogout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
