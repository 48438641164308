import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'

export interface BulletPointTextProps {
  children?: React.ReactNode
  index?: number
  className?: any
}

export const BulletPointText = ({ children, index, className }: BulletPointTextProps) => {
  return (
    <div css={[tw`flex flex-row items-center`, className]}>
      <div css={tw`w-5 shrink-0 p-0.5 h-5 items-center justify-center flex bg-black rounded`}>
        {typeof index === 'number' && (
          <Typography variant="overline" color="white">
            {index}
          </Typography>
        )}
      </div>
      <Typography css={tw`ml-3`} color={colors.SHADES_GREY_500}>
        {children}
      </Typography>
    </div>
  )
}
