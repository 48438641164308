import dayjs from 'dayjs'
import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'validateCarYear', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this

    if (!value || value.length < 4) {
      return createError({ path, message: errorMessage })
    }

    const date = dayjs(value, { format: 'YYYY' })
    const nextYear = dayjs().add(1, 'year')
    const oldest = dayjs('1900', { format: 'YYYY' })

    return (
      (nextYear.isAfter(date) && oldest.isBefore(date)) ||
      createError({ path, message: errorMessage })
    )
  })
})

export const getVehicleInsuranceSchema = ({
  withPolicyFile,
  isVehicleSchema,
  isNewVehicleSchema,
}: {
  withPolicyFile?: boolean
  isVehicleSchema?: boolean
  isNewVehicleSchema?: boolean
}) => {
  const shape: any = {
    file: Yup.string().required('Photo is required'),
  }

  if (withPolicyFile) {
    shape.policyDocument = Yup.string().required('Full policy document is required')
  }

  if (isNewVehicleSchema) {
    shape.type = Yup.string().required('Vehicle type is required')
  }

  if (isNewVehicleSchema && isVehicleSchema) {
    shape.file = Yup.string().required('Insurance is required')
    shape.year = Yup.string()
      .required('Vehicle year is required')
      // @ts-ignore
      .validateCarYear('Vehicle year is invalid')
    shape.carMake = Yup.string().required('Make is required')
    shape.carModel = Yup.string().required('Model is required')
    shape.vin = Yup.string().required('VIN is required').min(17, 'VIN is invalid')
  }

  return Yup.object().shape(shape)
}
