import { Checkbox, Typography } from '@mui/material'
import React, { memo, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { colors } from 'src/theme'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  OnboardingUploadVehicleInsurance,
  RecollectionSetNewDocsUploaded,
  RecollectionUploadVehicleInsurance,
} from 'src/constants/actionTypes'
import { OnboardingStepType } from 'src/constants/onboarding'
import { OnboardingStepIndex } from 'src/components/onboarding/OnboardingStepIndex'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'
import { Formik, FormikProps } from 'formik'
import { getVehicleInsuranceSchema } from './VehicleInsuranceForm.validator'
import { FormikFileInput } from '../shared/form/formik/FormikFileInput'
import { VehicleInsuranceFormValues } from './VehicleInsuranceForm.interface'
import { FormErrorMessage } from '../shared/form/FormErrorMessage'
import { FormikTextField } from '../shared/form/formik/FormikTextField'
import { FormikSelect } from '../shared/form/formik/FormikSelect'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'
import { nonInsuredVehicles, vehicleFormTypes, VehicleType } from 'src/constants/vehicle'
import { ShipmentMajor } from '@shopify/polaris-icons'
import { InfoContainer } from '../shared/InfoContainer'
import { CommonModal } from '../shared/CommonModal'
import { BulletPointText } from '../shared/BulletPointText'
import { OnboardingDriver } from 'src/interfaces/onboarding'
import { BasicRadioGroup } from '../shared/form/BasicRadioGroup'

interface Props {
  flow: 'onboarding' | 'recollect'
  driver?: OnboardingDriver
}

const USER_VEHICLE_TYPE = {
  sameVehicle: 'sameVehicle',
  newVehicle: 'newVehicle',
}

const initialValues: VehicleInsuranceFormValues = {
  file: undefined,
  policyDocument: undefined,
  type: VehicleType.Sedan,
  carMake: '',
  carModel: '',
  vin: '',
  year: '',
}

const YEAR_MASK = [/\d/, /\d/, /\d/, /\d/]

const VehicleInsuranceFormComponent = ({ flow, driver }: Props) => {
  const dispatch = useDispatch()

  const currentStep = useSelector(
    flow === 'recollect' ? getCurrentRecollectionStep : getCurrentOnboardingStep,
  )

  const formikRef = useRef<FormikProps<VehicleInsuranceFormValues>>(null)

  const [showPolicyFileInput, setShowPolicyFileInputModal] = useState<boolean>(false)
  const [showVinModal, setShowVinModal] = useState<boolean>(false)
  const [showInsuranceModal, setShowInsuranceModal] = useState<boolean>(false)
  const [vehicleType, setVehicleType] = useState<VehicleType>(driver?.type || VehicleType.Sedan)
  const [userVehicleType, setUserVehicleType] = useState<string>(
    flow === 'recollect' && driver?.type
      ? USER_VEHICLE_TYPE.sameVehicle
      : USER_VEHICLE_TYPE.newVehicle,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: VehicleInsuranceFormValues) => {
    if (!currentStep?.id) {
      return
    }

    setIsLoading(true)
    setError(null)

    const requestBody = {
      ...values,
      type:
        userVehicleType === USER_VEHICLE_TYPE.newVehicle
          ? values.type || driver?.type
          : driver?.type,
      carMake:
        userVehicleType === USER_VEHICLE_TYPE.newVehicle
          ? values.carMake || driver?.carMake
          : driver?.carMake,
      carModel:
        userVehicleType === USER_VEHICLE_TYPE.newVehicle
          ? values.carModel || driver?.carModel
          : driver?.carModel,
      vin:
        userVehicleType === USER_VEHICLE_TYPE.newVehicle ? values.vin || driver?.vin : driver?.vin,
      year:
        userVehicleType === USER_VEHICLE_TYPE.newVehicle
          ? values.year || driver?.year
          : driver?.year,
      stepId: currentStep?.id,
    }

    if (!showPolicyFileInput) {
      delete requestBody.policyDocument
    }

    if (nonInsuredVehicles.includes(vehicleType)) {
      delete requestBody.carMake
      delete requestBody.carModel
      delete requestBody.vin
      delete requestBody.year
    }

    try {
      if (flow === 'onboarding') {
        await createAsyncAction(dispatch, OnboardingUploadVehicleInsurance.request(requestBody))
      } else if (flow === 'recollect') {
        await createAsyncAction(dispatch, RecollectionUploadVehicleInsurance.request(requestBody))
        await createAsyncAction(dispatch, RecollectionSetNewDocsUploaded.success(true))
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const openVinModal = useCallback(() => {
    setShowVinModal(true)
  }, [])

  const closeVinModal = useCallback(() => {
    setShowVinModal(false)
  }, [])

  const openInsuranceModal = useCallback(() => {
    setShowInsuranceModal(true)
  }, [])

  const closeInsuranceModal = useCallback(() => {
    setShowInsuranceModal(false)
  }, [])

  const handleShowPolicyFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPolicyFileInputModal(event.target.checked)
  }

  const handleChangeUserVehicleType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === USER_VEHICLE_TYPE.sameVehicle && formikRef.current) {
        formikRef.current.resetForm({ values: initialValues })
        setVehicleType(driver?.type || VehicleType.Sedan)
      } else {
        setVehicleType(VehicleType.Sedan)
      }

      setUserVehicleType(event.target.value)
    },
    [driver?.type],
  )

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={handleFormSubmit}
      validationSchema={getVehicleInsuranceSchema({
        withPolicyFile: showPolicyFileInput,
        isVehicleSchema: !nonInsuredVehicles.includes(vehicleType),
        isNewVehicleSchema: userVehicleType === USER_VEHICLE_TYPE.newVehicle,
      })}
    >
      {({ handleSubmit, handleChange }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
            >
              <ShipmentMajor width={20} />
            </div>
            <OnboardingStepIndex type={OnboardingStepType.VehicleInsurance} />
            <Typography css={tw`my-2`} variant="h2">
              Vehicle and insurance
            </Typography>
            <Typography css={tw`mb-4 w-3/4`} color={colors.SHADES_GREY_400}>
              Input your vehicle details and upload your insurance card and any supporting
              information.
            </Typography>
            {flow === 'recollect' && !!driver?.type && (
              <div css={tw`mb-6`}>
                <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                  Select your state
                </Typography>
                <BasicRadioGroup
                  value={userVehicleType}
                  onChange={handleChangeUserVehicleType}
                  options={[
                    {
                      value: USER_VEHICLE_TYPE.sameVehicle,
                      label: "I'm using the same vehicle as before",
                    },
                    {
                      value: USER_VEHICLE_TYPE.newVehicle,
                      label: "I'm using new vehicle",
                    },
                  ]}
                />
              </div>
            )}
            {userVehicleType === USER_VEHICLE_TYPE.newVehicle && (
              <div css={tw`mb-6`}>
                <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                  Enter vehicle details
                </Typography>
                <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                  <div css={tw`w-full mb-4`}>
                    <FormikSelect
                      name="type"
                      label="Vehicle type"
                      placeholder="Select vehicle type"
                      options={vehicleFormTypes}
                      onChange={(e) => {
                        setVehicleType(e?.target?.value as VehicleType)
                        handleChange(e)
                      }}
                    />
                  </div>
                  {!nonInsuredVehicles.includes(vehicleType) && (
                    <>
                      <FormikTextField
                        name="year"
                        css={tw`w-full mb-4`}
                        label="Vehicle year"
                        placeholder="Enter year of production"
                        mask={YEAR_MASK}
                      />
                      <FormikTextField
                        name="carMake"
                        css={tw`w-full mb-4`}
                        label="Make"
                        placeholder="Enter vehicle make E.g. Toyota"
                      />
                      <FormikTextField
                        name="carModel"
                        css={tw`w-full mb-4`}
                        label="Model"
                        placeholder="Enter vehicle model E.g. Tacoma XL"
                      />
                      <FormikTextField
                        name="vin"
                        css={tw`w-full mb-4`}
                        label="VIN number"
                        placeholder="Enter VIN (17 characters)"
                      />
                      <div>
                        <InfoContainer onClick={openVinModal}>
                          Learn more about VIN number
                        </InfoContainer>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            <div css={tw`mb-6`}>
              <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                {nonInsuredVehicles.includes(vehicleType)
                  ? 'Upload Photo of the vehicle'
                  : 'Upload vehicle insurance'}
              </Typography>
              <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                {!nonInsuredVehicles.includes(vehicleType) && (
                  <>
                    <Typography css={tw`mb-4`} variant="h4">
                      Insurance requirements
                    </Typography>
                    <BulletPointText className={tw`mb-4`} index={1}>
                      The insurance must have you as an individual as a named insured OR acknowledge
                      you as insured on the schedule*
                    </BulletPointText>
                    <div css={tw`mb-4`}>
                      <InfoContainer>
                        *Please upload the complete policy document in the second box below to prove
                        you are on the schedule and there are no schedule exclusions.
                      </InfoContainer>
                    </div>
                    <BulletPointText className={tw`mb-4`} index={2}>
                      The insurance must be current and NOT expired.
                    </BulletPointText>
                    <BulletPointText className={tw`mb-4`} index={3}>
                      The insurance must be clearly visible and NOT blurred.
                    </BulletPointText>
                    <BulletPointText className={tw`mb-4`} index={4}>
                      Follow the example below to avoid uploading wrong document (do NOT upload
                      vehicle registration)
                    </BulletPointText>
                    <div css={tw`mb-4`}>
                      <InfoContainer onClick={openInsuranceModal}>
                        Vehicle insurance example
                      </InfoContainer>
                    </div>
                  </>
                )}
                <FormikFileInput name="file" />
              </div>
            </div>
            {!nonInsuredVehicles.includes(vehicleType) && (
              <div css={tw`mb-6`}>
                <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                  {'FULL POLICY DOCUMENT UPLOAD (OPTIONAL)'}
                </Typography>
                <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                  <div css={tw`flex flex-row items-center`}>
                    <Checkbox
                      checked={showPolicyFileInput}
                      onChange={handleShowPolicyFileInput}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <div css={tw`ml-2`}>
                      <Typography variant="h4">
                        My name is not displayed on the insurance card
                      </Typography>
                      <Typography color={colors.SHADES_GREY_500} css={tw`mt-2`}>
                        Upload addition document with your name on it (eg. Declaration Page/Coverage
                        Summary Page)
                      </Typography>
                    </div>
                  </div>
                  {showPolicyFileInput && (
                    <div css={tw`mt-4`}>
                      <FormikFileInput name="policyDocument" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full mt-2`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            <span>Submit and continue</span>
          </LoadingButton>
          <CommonModal
            title="About VIN number"
            text="A VIN is a unique , 17-character vehicle identification number. It can be found on your insurance card, title document, or registration. Highlighted in red on example."
            isOpen={showVinModal}
            image={require('../../assets/images/vehicle-vin-example.png')}
            onClose={closeVinModal}
          />
          <CommonModal
            title="Insurance example"
            text="Follow the provided examples."
            isOpen={showInsuranceModal}
            image={require('../../assets/images/vehicle-insurance-example.png')}
            onClose={closeInsuranceModal}
          />
        </div>
      )}
    </Formik>
  )
}

export const VehicleInsuranceForm = memo(VehicleInsuranceFormComponent)
