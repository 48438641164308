import { Button, Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { ContractorPortalPublicWrapper } from 'src/components/layout/contractorPortal/ContractorPortalPublicWrapper'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { CONTRACTOR_PORTAL_ROUTES } from 'src/constants/routes'
import { ChevronLeftMinor } from '@shopify/polaris-icons'
import { useSelector } from 'react-redux'
import { getContractorPortalPartners } from 'src/selectors/contractorPortal'
import { ContractorPortalPartner } from 'src/interfaces/contractorPortal'
import { ContractorPortalPartnerItem } from 'src/components/contractor-portal/ContractorPortalPartnerItem'

export const ContractorPortalSelectPartnerPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const contractorPortalPartners = useSelector(getContractorPortalPartners)

  const phone = useMemo(() => location?.state?.phone || '****', [location])

  const handleBack = useCallback(() => {
    navigate(CONTRACTOR_PORTAL_ROUTES.SIGN_IN_URL)
  }, [])

  const handleSelectPartner = useCallback(
    (partner: ContractorPortalPartner) => {
      navigate(`/contractor/enter-code`, { state: { phone, partnerId: partner.id } })
    },
    [phone],
  )

  if (!location?.state?.phone || !contractorPortalPartners.length) {
    return <Navigate to={CONTRACTOR_PORTAL_ROUTES.NOT_FOUND_URL} />
  }

  return (
    <ContractorPortalPublicWrapper>
      <Button
        css={tw`bg-[#F2E5FF] h-[42px] w-[82px] hover:bg-[rgba(226, 208, 244, 1)]`}
        onClick={handleBack}
      >
        <div css={tw`items-center flex flex-row`}>
          <ChevronLeftMinor fill={colors.PRIMARY_PURPLE_500} width={19} />
          <Typography color={colors.PRIMARY_PURPLE_500} css={tw`[font-weight: 600]`}>
            Back
          </Typography>
        </div>
      </Button>
      <Typography variant="h2" css={tw`mb-2 mt-6`}>
        Your phone number is linked to multiple partners
      </Typography>
      <Typography css={tw`mb-6`} color={colors.GRAY_DARK_COOL}>
        Select the partner you want to view.
      </Typography>
      {contractorPortalPartners.map((item) => (
        <ContractorPortalPartnerItem key={item.id} partner={item} onClick={handleSelectPartner} />
      ))}
    </ContractorPortalPublicWrapper>
  )
}
