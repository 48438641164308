import { ContractorPortalLogout, ContractorPortalFetchInvoices } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { ContractorPortalInvoicesState } from './types'

const getInitialState = (): ContractorPortalInvoicesState => ({
  contractorPortalInvoices: [],
  contractorPortalInvoicesCount: 0,
})

export const contractorPortalInvoicesReducer = (
  state: ContractorPortalInvoicesState = getInitialState(),
  action: Action<any>,
): ContractorPortalInvoicesState => {
  switch (action.type) {
    case ContractorPortalFetchInvoices.type.SUCCESS:
      return {
        ...state,
        contractorPortalInvoices: action.payload.invoices,
        contractorPortalInvoicesCount: action.payload.total,
      }
    case ContractorPortalLogout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
