import React, { memo, useMemo } from 'react'
import { Modal } from '@mui/material'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { ImportMinor } from '@shopify/polaris-icons'
import { ModalHeader } from '../shared/ModalHeader'

interface Props {
  isOpen?: boolean
  invoice?: any
  onClose(): void
}

const ContractorPortalInvoiceModalComponent = ({ isOpen, invoice, onClose }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur lg:p-4`}
    >
      <div css={[tw`bg-white lg:rounded-lg overflow-hidden w-[724px] flex flex-col h-full`]}>
        <ModalHeader title="Invoice" onClose={onClose} />
        <object data={invoice + '#toolbar=0'} type="application/pdf" css={tw`w-full h-full`}>
          <p css={tw`m-4`}>
            Oops! Your browser doesn't support PDFs preview. But you can still download it!
          </p>
        </object>
        <div css={tw`[transform: translate(-50%, -50%)] bottom-6 left-1/2 absolute`}>
          <a href={invoice} download>
            <Button size="small" rightIcon={<ImportMinor width={20} height={20} fill="white" />}>
              Download
            </Button>
          </a>
        </div>
      </div>
    </Modal>
  )
}

export const ContractorPortalInvoiceModal = memo(ContractorPortalInvoiceModalComponent)
