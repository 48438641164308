import React, { memo } from 'react'
import { VehicleInsuranceForm } from 'src/components/vehicle-insurance/VehicleInsuranceForm'
import { SecureWrapper } from '../layout/SecureWrapper'
import { OnboardingDriver } from 'src/interfaces/onboarding'

interface Props {
  flow: 'onboarding' | 'recollect'
  driver?: OnboardingDriver
}

const VehicleInsuranceComponent = ({ flow, driver }: Props) => {
  return (
    <SecureWrapper flow={flow}>
      <VehicleInsuranceForm flow={flow} driver={driver} />
    </SecureWrapper>
  )
}

export const VehicleInsurance = memo(VehicleInsuranceComponent)
