import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  getContractorPortalAuthToken,
  getOnboardingAuthToken,
  getRecollectionAuthToken,
} from 'src/selectors/auth'
import { OnboardingSignInPage } from 'src/pages/onboarding/auth/OnboardingSignInPage'
import { OnboardingEnterCodePage } from 'src/pages/onboarding/auth/OnboardingEnterCodePage'
import { OnboardingHomePage } from 'src/pages/onboarding/OnboardingHome'
import { NotFoundPage } from 'src/pages/NotFoundPage'
import { LoggedOutPage } from 'src/pages/LoggedOutPage'
import { DocsReuploadPage } from 'src/pages/recollection/docs/DocsReuploadPage'
import { RecollectionSignInPage } from 'src/pages/recollection/auth/RecollectionSignInPage'
import { RecollectionEnterCodePage } from 'src/pages/recollection/auth/RecollectionEnterCodePage'
import { OnboardingSecureHooksContainer } from './OnboardingSecureHooksContainer'
import { RecollectionSecureHooksContainer } from './RecollectionSecureHooksContainer'
import { OnboardingDocsPage } from './pages/onboarding/docs/OnboardingDocsPage'
import {
  CONTRACTOR_PORTAL_ROUTES,
  ONBOARDING_ROUTES,
  RECOLLECTION_ROUTES,
} from './constants/routes'
import { OnboardingSignUpPage } from './pages/onboarding/auth/OnboardingSignUpPage'
import { PartnerDisabledPage } from './pages/PartnerDisabledPage'
import { ContractorPortalSignInPage } from './pages/contractorPortal/auth/ContractorPortalSignInPage'
import { ContractorPortalEnterCodePage } from './pages/contractorPortal/auth/ContractorPortalEnterCodePage'
import { ContractorPortalSelectPartnerPage } from './pages/contractorPortal/auth/ContractorPortalSelectPartnerPage'
import { ContractorPortalHomePage } from './pages/contractorPortal/ContractorPortalHomePage'
import { ContractorPortalSecureHooksContainer } from './ContractorPortalSecureHooksContainer'
import { ContractorPortalInvoicesPage } from './pages/contractorPortal/ContractorPortalInvoicesPage'

export const AppRoutes = () => {
  const onboardingAuthToken = useSelector(getOnboardingAuthToken)
  const recollectionAuthToken = useSelector(getRecollectionAuthToken)
  const contractorPortalAuthToken = useSelector(getContractorPortalAuthToken)

  return (
    <BrowserRouter>
      <Routes>
        {onboardingAuthToken ? (
          <>
            <Route path={ONBOARDING_ROUTES.HOME_URL} element={<OnboardingHomePage />} />
            <Route path={ONBOARDING_ROUTES.DOCS_URL} element={<OnboardingDocsPage />} />
            <Route path="onboarding/*" element={<Navigate to={ONBOARDING_ROUTES.HOME_URL} />} />
          </>
        ) : (
          <>
            <Route path={ONBOARDING_ROUTES.SIGN_IN_URL} element={<OnboardingSignInPage />} />
            <Route path={ONBOARDING_ROUTES.SIGN_UP_URL} element={<OnboardingSignUpPage />} />
            <Route path={ONBOARDING_ROUTES.ENTER_CODE_URL} element={<OnboardingEnterCodePage />} />
            <Route
              path={ONBOARDING_ROUTES.NOT_FOUND_URL}
              element={<NotFoundPage flow="onboarding" />}
            />
            <Route
              path={ONBOARDING_ROUTES.LOGGED_OUT_URL}
              element={<LoggedOutPage flow="onboarding" />}
            />
            <Route
              path={ONBOARDING_ROUTES.PARTNER_DISABLED_URL}
              element={<PartnerDisabledPage />}
            />
            <Route
              path="onboarding/*"
              element={<Navigate to={ONBOARDING_ROUTES.NOT_FOUND_URL} />}
            />
          </>
        )}
        {recollectionAuthToken ? (
          <>
            <Route path={RECOLLECTION_ROUTES.DOCS_REUPLOAD_URL} element={<DocsReuploadPage />} />
            <Route
              path="recollection/*"
              element={<Navigate to={RECOLLECTION_ROUTES.DOCS_REUPLOAD_URL} />}
            />
          </>
        ) : (
          <>
            <Route path={RECOLLECTION_ROUTES.SIGN_IN_URL} element={<RecollectionSignInPage />} />
            <Route
              path={RECOLLECTION_ROUTES.ENTER_CODE_URL}
              element={<RecollectionEnterCodePage />}
            />
            <Route
              path={RECOLLECTION_ROUTES.NOT_FOUND_URL}
              element={<NotFoundPage flow="recollect" />}
            />
            <Route
              path={RECOLLECTION_ROUTES.LOGGED_OUT_URL}
              element={<LoggedOutPage flow="recollect" />}
            />
            <Route
              path={RECOLLECTION_ROUTES.PARTNER_DISABLED_URL}
              element={<PartnerDisabledPage />}
            />
            <Route
              path="recollection/*"
              element={<Navigate to={RECOLLECTION_ROUTES.NOT_FOUND_URL} />}
            />
          </>
        )}
        {contractorPortalAuthToken ? (
          <>
            <Route
              path={CONTRACTOR_PORTAL_ROUTES.HOME_URL}
              element={<ContractorPortalHomePage />}
            />
            <Route
              path={CONTRACTOR_PORTAL_ROUTES.INVOICES_URL}
              element={<ContractorPortalInvoicesPage />}
            />
            <Route
              path="contractor/*"
              element={<Navigate to={CONTRACTOR_PORTAL_ROUTES.HOME_URL} />}
            />
          </>
        ) : (
          <>
            <Route
              path={CONTRACTOR_PORTAL_ROUTES.SIGN_IN_URL}
              element={<ContractorPortalSignInPage />}
            />
            <Route
              path={CONTRACTOR_PORTAL_ROUTES.SELECT_PARTNER_URL}
              element={<ContractorPortalSelectPartnerPage />}
            />
            <Route
              path={CONTRACTOR_PORTAL_ROUTES.ENTER_CODE_URL}
              element={<ContractorPortalEnterCodePage />}
            />
            <Route
              path={CONTRACTOR_PORTAL_ROUTES.NOT_FOUND_URL}
              element={<NotFoundPage flow="contractorPortal" />}
            />
            <Route
              path={CONTRACTOR_PORTAL_ROUTES.LOGGED_OUT_URL}
              element={<LoggedOutPage flow="contractorPortal" />}
            />
            <Route
              path={CONTRACTOR_PORTAL_ROUTES.PARTNER_DISABLED_URL}
              element={<PartnerDisabledPage />}
            />
            <Route
              path="contractor/*"
              element={<Navigate to={CONTRACTOR_PORTAL_ROUTES.NOT_FOUND_URL} />}
            />
          </>
        )}
        <Route path="not-found" element={<NotFoundPage />} />
        <Route path="logged-out" element={<LoggedOutPage />} />
        <Route path="partner-disabled" element={<PartnerDisabledPage />} />
        <Route path="*" element={<Navigate to="not-found" />} />
      </Routes>
      {onboardingAuthToken && <OnboardingSecureHooksContainer />}
      {recollectionAuthToken && <RecollectionSecureHooksContainer />}
      {contractorPortalAuthToken && <ContractorPortalSecureHooksContainer />}
    </BrowserRouter>
  )
}
