import { Formik } from 'formik'
import { useMemo, useRef, useState } from 'react'
import { DocusignFieldsFormModalValues } from './DocusignFieldsFormModal.interface'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography, Modal, Button } from '@mui/material'
import { colors } from 'src/theme'
import { DocusignSigningField } from 'src/interfaces/docusign'
import { getValidationSchema } from './DocusignFieldsFormModal.validator'
import { ChevronLeftMinor } from '@shopify/polaris-icons'
import _ from 'lodash'
import { DocusignFieldsFormModalInputList } from './DocusignFieldsFormModalInputList'
import { DocusignFieldTabLabel } from 'src/constants/docusign'

interface Props {
  isOpen?: boolean
  fields: Array<DocusignSigningField>
  onSubmit(
    values: Array<{
      tabLabel: string
      value: string
    }>,
  ): Promise<void>
  onClose(): void
}

export const DocusignFieldsFormModal = ({ isOpen, fields, onSubmit, onClose }: Props) => {
  const formikRef = useRef<any>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const container = useMemo(() => document.getElementById('root'), [])

  const handleFormSubmit = async (values: DocusignFieldsFormModalValues) => {
    setIsLoading(true)
    setError(null)

    const mmsOregonAddressField = fields.find(
      (field) => field.tabLabel === DocusignFieldTabLabel.mmsOregonAddress,
    )

    try {
      const payload = { ...values }

      if (mmsOregonAddressField && values[DocusignFieldTabLabel.state] === 'OR') {
        payload[DocusignFieldTabLabel.mmsOregonAddress] = `${
          values[DocusignFieldTabLabel.address]
        }, ${values[DocusignFieldTabLabel.city]}, ${values[DocusignFieldTabLabel.state]}, ${
          values[DocusignFieldTabLabel.zipcode]
        }`
      }

      await onSubmit(
        Object.entries(payload).map((item) => ({
          tabLabel: item[0],
          value: !_.isNil(item[1]) ? item[1].toString() : '',
        })),
      )
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      // onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`w-full h-full flex flex-col justify-center backdrop-blur`}
    >
      <div css={tw`w-full max-h-full m-auto overflow-auto p-4`}>
        <div css={tw`max-w-[390px] m-auto flex-col p-4 pt-6 bg-white rounded-2xl`}>
          <Formik
            initialValues={{
              ...fields.reduce((res, item) => {
                if (item.tabType === 'checkbox') {
                  res[item.tabLabel] = item.value || false
                } else {
                  res[item.tabLabel] = item.value || ''
                }

                return res
              }, {} as any),
            }}
            innerRef={formikRef}
            validationSchema={getValidationSchema(fields)}
            onSubmit={handleFormSubmit}
          >
            {({ handleSubmit }) => (
              <div css={tw`h-full w-full flex flex-col`}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onClose}
                  css={tw`bg-[#EDEDED] hover:bg-gray-200 h-[42px] w-[82px] mb-4`}
                >
                  <div css={tw`items-center flex flex-row`}>
                    <ChevronLeftMinor width={20} />
                    <Typography css={tw`text-black [font-weight: 600]`}>Back</Typography>
                  </div>
                </Button>
                <Typography css={tw`mb-2`} variant="h2">
                  Sign the contract
                </Typography>
                <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
                  To begin signing documents, please provide the following basic information.
                </Typography>
                <DocusignFieldsFormModalInputList fields={fields} />
                <FormErrorMessage css={tw`mb-4`} error={error} />
                <LoadingButton
                  css={tw`w-full`}
                  variant="contained"
                  loading={isLoading}
                  onClick={() => handleSubmit()}
                >
                  <span>Submit and continue</span>
                </LoadingButton>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
