import { Typography } from '@mui/material'
import React, { ChangeEvent, memo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { colors } from 'src/theme'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { OnboardingUploadEIN, RecollectionUploadEIN } from 'src/constants/actionTypes'
import EINIcon from 'src/assets/icons/bgc.svg'
import { OnboardingStepType } from 'src/constants/onboarding'
import { OnboardingStepIndex } from 'src/components/onboarding/OnboardingStepIndex'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'
import { Formik } from 'formik'
import { EINFormSchema } from './EINForm.validator'
import { FormikFileInput } from '../shared/form/formik/FormikFileInput'
import { EINFormValues } from './EINForm.interface'
import { FormErrorMessage } from '../shared/form/FormErrorMessage'
import { BasicRadioGroup } from '../shared/form/BasicRadioGroup'
import { FormikTextField } from '../shared/form/formik/FormikTextField'
import { FormikSelect } from '../shared/form/formik/FormikSelect'
import { states } from 'src/constants/states'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'
import { usePersona } from 'src/hooks/usePersona'

interface Props {
  flow: 'onboarding' | 'recollect'
}

const initialValues: EINFormValues = {
  file: undefined,
  ein: '',
  businessName: '',
  streetAddress: '',
  city: '',
  state: '',
  zipCode: '',
}

const showFormValues = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
]

const EINFormComponent = ({ flow }: Props) => {
  const dispatch = useDispatch()

  const currentStep = useSelector(
    flow === 'recollect' ? getCurrentRecollectionStep : getCurrentOnboardingStep,
  )

  const { startPersona } = usePersona(flow)

  const [showForm, setShowForm] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: EINFormValues) => {
    if (!currentStep?.id) {
      return
    }

    setIsLoading(true)
    setError(null)

    const requestBody = showForm
      ? {
          ...values,
          stepId: currentStep.id,
          isBusinessEntityFlow: true,
        }
      : {
          stepId: currentStep.id,
          isBusinessEntityFlow: false,
        }

    try {
      if (flow === 'onboarding') {
        await createAsyncAction(dispatch, OnboardingUploadEIN.request(requestBody))
      } else if (flow === 'recollect') {
        await createAsyncAction(dispatch, RecollectionUploadEIN.request(requestBody))
      }

      if (showForm) {
        await startPersona(currentStep.id)
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeShowForm = useCallback((e: ChangeEvent<{ value: string }>) => {
    setShowForm(e.target.value === 'yes')
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={showForm ? EINFormSchema : undefined}
    >
      {({ handleSubmit }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
            >
              <img src={EINIcon} />
            </div>
            {flow === 'onboarding' && <OnboardingStepIndex type={OnboardingStepType.EIN} />}
            <Typography css={tw`mb-6 mt-2`} variant="h2">
              Business Entity
            </Typography>
            <Typography color={colors.SHADES_GREY_400}>
              Please enter your business information, if applicable.
            </Typography>
            <div css={tw`mt-6 bg-white p-4 pb-0 rounded-2xl mb-8`}>
              <Typography color={colors.SHADES_GREY_400} css={tw`mb-3`} variant="h6">
                Are you registering as a business entity? (This only applies to contractors who have
                a registered business name and EIN, and wish to register with GigSafe as a business.
                If this does not apply to you, please check 'No').
              </Typography>
              <BasicRadioGroup options={showFormValues} onChange={handleChangeShowForm} />
            </div>
            {showForm && (
              <div>
                <Typography variant="overline" color={colors.SHADES_GREY_400}>
                  ENTER YOUR BUSINESS INFORMATION
                </Typography>
                <div css={tw`bg-white p-4 rounded-2xl mb-8 mt-2`}>
                  <FormikTextField
                    name="ein"
                    css={tw`w-full mb-6`}
                    label="EIN"
                    placeholder="Enter your EIN"
                  />
                  <FormikTextField
                    name="businessName"
                    css={tw`w-full`}
                    label="Business Name"
                    placeholder="Enter your business name"
                  />
                </div>
                <Typography variant="overline" color={colors.SHADES_GREY_400}>
                  ENTER YOUR BUSINESS ADDRESS
                </Typography>
                <div css={tw`bg-white p-4 rounded-2xl mb-8 mt-2`}>
                  <FormikTextField
                    name="streetAddress"
                    css={tw`w-full mb-6`}
                    label="Street address"
                    placeholder="Enter street address"
                  />
                  <FormikTextField
                    name="city"
                    css={tw`w-full mb-6`}
                    label="City"
                    placeholder="Enter city"
                  />
                  <div css={tw`w-full mb-6`}>
                    <FormikSelect
                      name="state"
                      label="State"
                      placeholder="Select state"
                      options={states}
                    />
                  </div>
                  <FormikTextField
                    name="zipCode"
                    css={tw`w-full`}
                    label="Zip code"
                    placeholder="Enter zip code"
                  />
                </div>
                <Typography variant="overline" color={colors.SHADES_GREY_400}>
                  UPLOAD EIN LETTER
                </Typography>
                <div css={tw`bg-white p-4 rounded-2xl mb-8 mt-2`}>
                  <FormikFileInput name="file" />
                </div>
              </div>
            )}
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full`}
            variant="contained"
            onClick={() => {
              handleSubmit()
            }}
            loading={isLoading}
            disabled={typeof showForm !== 'boolean'}
          >
            Submit document
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}

export const EINForm = memo(EINFormComponent)
