import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ContractorPortalLogout,
  OnboardingLogout,
  RecollectionLogout,
} from 'src/constants/actionTypes'
import { PARTNER_DISABLED_ERROR } from 'src/constants/auth'
import {
  CONTRACTOR_PORTAL_ROUTES,
  ONBOARDING_ROUTES,
  RECOLLECTION_ROUTES,
} from 'src/constants/routes'
import { createAsyncAction, Action } from 'src/utils/reduxUtils'

export const useAsyncDispatchWithErrorToast = (
  platform: 'recollection' | 'onboarding' | 'contractorPortal',
) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const snackbar = useSnackbar()

  const asyncDispatchWithErrorToast = useCallback(
    async <T, K = any>(action: Action<K>): Promise<T | null> => {
      try {
        const result = await createAsyncAction<T, K>(dispatch, action)

        return result
      } catch (err: any) {
        if (err === PARTNER_DISABLED_ERROR) {
          if (platform === 'recollection') {
            dispatch(RecollectionLogout.request())
            setTimeout(() => {
              navigate(RECOLLECTION_ROUTES.PARTNER_DISABLED_URL)
            }, 300)
          }
          if (platform === 'onboarding') {
            dispatch(OnboardingLogout.request())
            setTimeout(() => {
              navigate(ONBOARDING_ROUTES.PARTNER_DISABLED_URL)
            }, 300)
          }
          if (platform === 'contractorPortal') {
            dispatch(ContractorPortalLogout.request())
            setTimeout(() => {
              navigate(CONTRACTOR_PORTAL_ROUTES.PARTNER_DISABLED_URL)
            }, 300)
          }

          return null
        }

        const errorMessage =
          typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Something went wrong'

        snackbar?.enqueueSnackbar(errorMessage, { variant: 'error' })

        return null
      }
    },
    [platform],
  )

  return { asyncDispatchWithErrorToast }
}
