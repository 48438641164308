import React, { Fragment, memo, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getOnboardingDriver, getIsOnboardingDriverInitialized } from 'src/selectors/driver'
import { Button, Typography, useMediaQuery } from '@mui/material'
import tw from 'twin.macro'
import { colors } from 'src/theme'
import PoweredLogo from 'src/assets/images/poweredby.svg'
import PoweredLogoWhiteVertical from 'src/assets/images/poweredby-white-vertical.svg'
import BackgroundShield from 'src/assets/images/background-shield.svg'
import { LoadingPage } from '../LoadingPage'
import { ChecklistAlternateMajor, MobileBackArrowMajor } from '@shopify/polaris-icons'
import {
  OnboardingStatus,
  onboardingStepDataMap,
  OnboardingStepType,
} from 'src/constants/onboarding'
import {
  getIsOnboardingFailed,
  getIsOnboardingFinished,
  getIsOnboardingStarted,
  getCurrentOnboardingStep,
  getOnboardingSteps,
  getOnboardingStepsRemainingTimeToComplete,
} from 'src/selectors/onboarding'
import { useNavigate } from 'react-router-dom'
import { OnboardingSuccessPage } from '../OnboardingSuccessPage'
import { addS, addSForString } from 'src/utils/baseUtils'
import { ONBOARDING_ROUTES } from 'src/constants/routes'
import { OnboardingFailedPage } from '../OnboardingFailedPage'
import { StepItem } from 'src/components/onboarding/home/StepItem'
import { Settings } from 'src/components/settings/Settings'

const OnboardingHomePageComponent = () => {
  const navigate = useNavigate()
  const isLargeScreen = useMediaQuery('(min-width:1024px)')
  const isSmallScreen = useMediaQuery('(max-width:768px)')

  const isDriverInitialized = useSelector(getIsOnboardingDriverInitialized)
  const driver = useSelector(getOnboardingDriver)
  const onboardingSteps = useSelector(getOnboardingSteps)
  const stepsTotalTimeToComplete = useSelector(getOnboardingStepsRemainingTimeToComplete)
  const nextOnboardingStep = useSelector(getCurrentOnboardingStep)
  const isOnboardingStarted = useSelector(getIsOnboardingStarted)
  const isOnboardingFinished = useSelector(getIsOnboardingFinished)
  const isOnboardingFailed = useSelector(getIsOnboardingFailed)

  const firstDisabledStepIndex = useMemo(
    () => onboardingSteps.findIndex((item) => item.disabled),
    [onboardingSteps],
  )

  const handleClickLogo = useCallback(() => {
    window.open('https://www.gigsafe.com/')
  }, [])

  const handleStartOnboarding = useCallback(() => {
    navigate(ONBOARDING_ROUTES.DOCS_URL)
  }, [nextOnboardingStep?.type])

  if (!isDriverInitialized) {
    return <LoadingPage />
  }

  if (isOnboardingFailed) {
    return <OnboardingFailedPage />
  }

  if (isOnboardingFinished) {
    return <OnboardingSuccessPage />
  }

  return (
    <div>
      <div css={tw`w-full shadow fixed z-50`}>
        <div css={tw`bg-[#440b7c] w-full h-16 p-4 top-0 flex justify-between items-center`}>
          <img src={driver?.partner?.logoUrl} css={tw`h-[28px]`} />
          <Settings flow="onboarding" />
        </div>
        <div css={tw`bg-[#F2E5FF] w-full h-9 flex items-center justify-center flex-row`}>
          <ChecklistAlternateMajor width={20} />
          <Typography css={tw`ml-2`} variant="caption" fontWeight={600}>
            Contractor's onboarding
          </Typography>
        </div>
      </div>

      <div css={tw`h-[100vh] lg:overflow-y-scroll lg:flex`}>
        <div
          css={tw`h-[100vh] bg-[#300258] pt-16 lg:float-left lg:w-[50%] flex flex-col items-center lg:pr-[14%] lg:items-end justify-end md:justify-end md:pb-[72px] overflow-hidden px-4 sm:px-0`}
        >
          <img src={BackgroundShield} css={tw`absolute top-1/4 lg:w-[280px]`} />
          <div css={tw`lg:max-w-[296px] max-w-[390px] mx-4 flex flex-col`}>
            <Typography variant="overline" css={tw`mb-2`} color={colors.PRIMARY_PURPLE_200}>
              ONBOARDING
            </Typography>
            <Typography variant="h1" color={colors.GRAY_WHITE}>
              Welcome,
            </Typography>
            <Typography css={tw`mb-2`} variant="h1" color={colors.GRAY_WHITE}>
              {`${driver?.firstName} ${driver?.lastName}`}
            </Typography>
            <Typography color={colors.PRIMARY_PURPLE_200} css={tw`mb-6`}>
              {`Tap "${
                isOnboardingStarted ? 'Continue' : 'Start'
              } Onboarding" to complete essential steps and qualify for ${
                driver?.partner?.displayName
                  ? addSForString(driver?.partner?.displayName)
                  : "partner's"
              } job
              opportunities`}
            </Typography>
            <Button
              css={tw`bg-[#F2E5FF] hover:bg-[rgba(226, 208, 244, 1)] w-full`}
              onClick={handleStartOnboarding}
            >
              {`${isOnboardingStarted ? 'Continue' : 'Start'} Onboarding`}
            </Button>
            {isSmallScreen && (
              <>
                <Typography color={colors.PRIMARY_PURPLE_200} css={tw`mt-4 mb-2 text-center`}>
                  Continue scrolling to review the required steps
                </Typography>
                <MobileBackArrowMajor fill={colors.GRAY_WHITE} css={tw`-rotate-90`} height={20} />
                <div css={tw`w-[100%] h-[100px] bg-[#440B7C] [border-radius: 50%] mb-[-80px]`} />
              </>
            )}
            {isLargeScreen && (
              <div css={tw`mt-12 h-11 flex flex-1`}>
                <img src={PoweredLogoWhiteVertical} />
              </div>
            )}
          </div>
        </div>
        <div
          css={tw`lg:float-left lg:w-[50%] lg:overflow-y-auto bg-[#F6F6F6] lg:pl-[10%] lg:items-start lg:bg-[#23003F] py-8 px-4 flex flex-col items-center`}
        >
          <div css={tw`max-w-[400px] lg:mt-32`}>
            <Typography color={isLargeScreen ? colors.GRAY_WHITE : undefined} variant="h2">
              Required steps
            </Typography>
            <Typography
              color={isLargeScreen ? colors.PRIMARY_PURPLE_200 : colors.SHADES_GREY_500}
              css={tw`my-2`}
            >
              Progress saves after each step - pause at your convenience and resume later.
            </Typography>
            <Typography
              variant="caption"
              color={isLargeScreen ? colors.GRAY_WHITE : colors.PRIMARY_PURPLE_500}
            >
              {`Complete the process in approximately ${stepsTotalTimeToComplete} ${addS(
                stepsTotalTimeToComplete,
                'minute',
              )}.`}
            </Typography>
            <div css={tw`mb-4`} />
            {onboardingSteps
              .filter((item) => Boolean(onboardingStepDataMap[item.type]))
              .map((item, index) => {
                const stepData = onboardingStepDataMap[item.type]({
                  optional: Boolean(item.optional),
                })

                let title = stepData.title
                let text = stepData.text

                if (item.type === OnboardingStepType.Form) {
                  if (item.metadata?.title) {
                    title = item.metadata?.title
                  }
                  if (item.metadata?.description) {
                    text = item.metadata?.description
                  }
                }

                return (
                  <Fragment key={item.type}>
                    <div css={tw`mt-2`} />
                    {stepData && (
                      <StepItem
                        icon={stepData.icon}
                        title={title}
                        text={text}
                        finishTime={stepData.finishTime}
                        index={index + 1}
                        completed={item.status !== OnboardingStatus.Created}
                        disabled={firstDisabledStepIndex === index}
                      />
                    )}
                  </Fragment>
                )
              })}
            {!isLargeScreen && (
              <>
                <Button
                  css={tw`w-full mt-6 h-[56px]`}
                  variant="contained"
                  onClick={handleStartOnboarding}
                >
                  {`${isOnboardingStarted ? 'Continue' : 'Start'} Onboarding`}
                </Button>
                <div
                  css={tw`flex items-center cursor-pointer mt-6 justify-center w-full z-20`}
                  onClick={handleClickLogo}
                >
                  <img src={PoweredLogo} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const OnboardingHomePage = memo(OnboardingHomePageComponent)
