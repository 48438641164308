import { OnboardingLogout, OnboardingSetAuthToken } from 'src/constants/actionTypes'
import { authService } from 'src/services/AuthService'
import { Action } from 'src/utils/reduxUtils'
import { OnboardingAuthState } from './types'

const getInitialState = (): OnboardingAuthState => ({
  onboardingAuthToken: authService.getStoredOnboardingAuthToken(),
})

export const onboardingAuthReducer = (
  state: OnboardingAuthState = getInitialState(),
  action: Action<any>,
): OnboardingAuthState => {
  switch (action.type) {
    case OnboardingSetAuthToken.type.SUCCESS:
      return {
        ...state,
        onboardingAuthToken: action.payload,
      }
    case OnboardingLogout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
