import { ReactNode } from 'react'
import tw from 'twin.macro'
import background from 'src/assets/images/background.svg'
import PoweredLogoWhite from 'src/assets/images/poweredby-white.svg'
import LogoWhite from 'src/assets/images/gigsafe-logo-white.svg'
import { useSelector } from 'react-redux'
import { getRecollectionAuthToken } from 'src/selectors/auth'
import { RefreshMinor } from '@shopify/polaris-icons'
import { Typography } from '@mui/material'
import { Settings } from 'src/components/settings/Settings'

interface Props {
  children: ReactNode
}

const handleClickLogo = () => {
  window.open('https://www.gigsafe.com/')
}

export const RecollectionPublicWrapper = ({ children }: Props) => {
  const authToken = useSelector(getRecollectionAuthToken)

  return (
    <div css={tw`h-full w-full flex flex-col`}>
      <div css={tw`w-full shadow fixed z-50`}>
        <div css={tw`bg-[#440b7c] w-full h-16 p-4 flex justify-between items-center`}>
          <img src={LogoWhite} css={tw`h-[28px]`} />
          <Settings flow="recollect" />
        </div>
        {authToken && (
          <div css={tw`bg-[#F2E5FF] w-full h-9 flex items-center justify-center flex-row`}>
            <RefreshMinor width={20} />
            <Typography css={tw`ml-2`} variant="caption" fontWeight={600}>
              Contractor's recollection
            </Typography>
          </div>
        )}
      </div>
      <div
        css={tw`flex flex-1 bg-[#300258] items-center justify-center flex-col pb-4 lg:pb-6 pt-20 lg:pt-[112px]`}
      >
        <img src={background} css={tw`fixed top-0 bottom-0 z-0 h-full`} />
        <div />
        <div css={tw`bg-white  max-w-[390px] mx-4 rounded-2xl z-10 overflow-hidden`}>
          <div css={tw`px-4 pt-6 pb-4`}>{children}</div>
        </div>
        <div
          css={tw`flex items-center cursor-pointer mt-6 justify-center w-full z-20`}
          onClick={handleClickLogo}
        >
          <img src={PoweredLogoWhite} />
        </div>
      </div>
    </div>
  )
}
