import React, { memo } from 'react'
import { VehicleInsurance } from 'src/components/vehicle-insurance/VehicleInsurance'
import { useSelector } from 'react-redux'
import { getRecollectionDriver } from 'src/selectors/driver'

const VehicleInsurancePageComponent = () => {
  const driver = useSelector(getRecollectionDriver)

  console.log('driver', driver)

  return <VehicleInsurance flow="recollect" driver={driver ?? undefined} />
}

export const VehicleInsurancePage = memo(VehicleInsurancePageComponent)
