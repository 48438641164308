import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { ContractorPortalPublicWrapper } from 'src/components/layout/contractorPortal/ContractorPortalPublicWrapper'
import { SignInForm } from 'src/components/auth/sign-in-form/SignInForm'
import { useNavigate } from 'react-router-dom'
import { store } from 'src/reduxStore'

export const ContractorPortalSignInPage = () => {
  const navigate = useNavigate()

  const handleSuccess = (phone: string) => {
    const partners = store.getState().contractorPortalPartners.contractorPortalPartners

    if (!partners.length) {
      return
    }

    if (partners.length === 1) {
      navigate(`/contractor/enter-code`, { state: { phone, partnerId: partners[0].id } })

      return
    }

    navigate(`/contractor/select-partner`, { state: { phone } })
  }

  return (
    <ContractorPortalPublicWrapper>
      <Typography variant="h2" css={tw`mb-2`}>
        Welcome to GigSafe's Contractor Portal
      </Typography>
      <Typography css={tw`mb-6`} color={colors.GRAY_DARK_COOL}>
        Log in securely using the phone number you used during onboarding.
      </Typography>
      <SignInForm platform="contractorPortal" onSuccess={handleSuccess} />
    </ContractorPortalPublicWrapper>
  )
}
