import React, { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { PublicWrapper } from 'src/components/layout/PublicWrapper'
import { colors } from 'src/theme'
import LoggedOutImage from 'src/assets/images/logged-out.png'

interface Props {
  flow?: 'onboarding' | 'recollect' | 'contractorPortal'
}

const LoggedOutPageComponent = ({ flow }: Props) => {
  return (
    <PublicWrapper flow={flow}>
      <div css={tw`h-full flex flex-col justify-between`}>
        <img src={LoggedOutImage} css={tw`h-[220px] w-[280px] self-center`} />
        <div>
          <Typography css={tw`mt-4 text-center`} variant="h2">
            You have been logged out
          </Typography>
          <Typography css={tw`my-4 text-center`} color={colors.GRAY_DARK_COOL}>
            If you'd like to log in again, just use the link from the email. For any issues, reach
            out to our support team.
          </Typography>
        </div>
      </div>
    </PublicWrapper>
  )
}

export const LoggedOutPage = memo(LoggedOutPageComponent)
