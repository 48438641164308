import { Button, Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { ContractorPortalPublicWrapper } from 'src/components/layout/contractorPortal/ContractorPortalPublicWrapper'
import { EnterCodeForm } from 'src/components/auth/enter-code-form/EnterCodeForm'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { CONTRACTOR_PORTAL_ROUTES } from 'src/constants/routes'
import { ChevronLeftMinor } from '@shopify/polaris-icons'

export const ContractorPortalEnterCodePage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const phone = useMemo(() => location?.state?.phone || '****', [location])
  const partnerId = useMemo(() => location?.state?.partnerId, [location])

  const handleBack = useCallback(() => {
    navigate(CONTRACTOR_PORTAL_ROUTES.SELECT_PARTNER_URL)
  }, [])

  if (!location?.state?.phone) {
    return <Navigate to={CONTRACTOR_PORTAL_ROUTES.NOT_FOUND_URL} />
  }

  return (
    <ContractorPortalPublicWrapper>
      <Button
        css={tw`bg-[#F2E5FF] h-[42px] w-[82px] hover:bg-[rgba(226, 208, 244, 1)]`}
        onClick={handleBack}
      >
        <div css={tw`items-center flex flex-row`}>
          <ChevronLeftMinor fill={colors.PRIMARY_PURPLE_500} width={19} />
          <Typography color={colors.PRIMARY_PURPLE_500} css={tw`[font-weight: 600]`}>
            Back
          </Typography>
        </div>
      </Button>
      <Typography variant="h2" css={tw`mb-2 mt-6`}>
        Enter the verification code we just sent.
      </Typography>
      <Typography css={tw`mb-6`} color={colors.GRAY_DARK_COOL}>
        {`Code has been sent to number ending ${phone.slice(-4)}`}
      </Typography>
      <EnterCodeForm platform="contractorPortal" phone={phone} partnerId={partnerId} />
    </ContractorPortalPublicWrapper>
  )
}
