import React, { ReactNode } from 'react'
import { OnboardingPublicWrapper } from './onboarding/OnboardingPublicWrapper'
import { RecollectionPublicWrapper } from './recollection/RecollectionPublicWrapper'
import { ContractorPortalPublicWrapper } from './contractorPortal/ContractorPortalPublicWrapper'
import { BasePublicWrapper } from './BasePublicWrapper'

interface Props {
  flow?: 'onboarding' | 'recollect' | 'contractorPortal'
  children?: ReactNode
}

export const PublicWrapper = ({ flow, children }: Props) => {
  if (flow === 'onboarding') {
    return <OnboardingPublicWrapper children={children} />
  }

  if (flow === 'recollect') {
    return <RecollectionPublicWrapper children={children} />
  }

  if (flow === 'contractorPortal') {
    return <ContractorPortalPublicWrapper children={children} />
  }

  return <BasePublicWrapper children={children} />
}
