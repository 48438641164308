import React, { memo, useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { Button, Modal, Typography } from '@mui/material'
import { colors } from 'src/theme'
import {
  // ChatMajor,
  DuplicateMinor,
  EmailMajor,
} from '@shopify/polaris-icons'
import { showToast } from 'src/utils/toast'

const EMAIL = 'help@gigsafe.com'
// const PHONE = '+1 (820) 444-7233'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const GetHelpModalComponent = ({ isOpen, onClose }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  const handleCopyEmail = useCallback(() => {
    navigator.clipboard.writeText(EMAIL)

    showToast('Copied!')
  }, [])

  // const handleCopyPhone = useCallback(() => {
  //   navigator.clipboard.writeText(PHONE)

  //   showToast('Copied!')
  // }, [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex flex-col justify-end xsm:justify-center items-center backdrop-blur`}
    >
      <div
        css={tw`flex-col p-4 pt-6 bg-white rounded-2xl rounded-b-none xsm:rounded-b-2xl w-full xsm:w-[390px]`}
      >
        <Typography variant="h2">Get help now!</Typography>
        <Typography css={tw`mt-2 mb-6`} color={colors.SHADES_GREY_400}>
          Choose your preferred option to reach us.
        </Typography>
        <div css={tw`bg-[#EDEDED] h-[1px] w-full`} />
        <div css={tw`flex flex-row justify-between items-center px-2 py-4`}>
          <div css={tw`flex flex-row items-center`}>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mr-4`}
            >
              <EmailMajor width={20} />
            </div>
            <div>
              <Typography css={tw`mb-0.5`} variant="h4">
                Via email
              </Typography>
              <Typography color={colors.SHADES_GREY_400}>{EMAIL}</Typography>
            </div>
          </div>
          <Button
            css={tw`bg-[#F2E5FF] h-[36px] w-[84px] hover:bg-[rgba(226, 208, 244, 1)]`}
            onClick={handleCopyEmail}
          >
            <div css={tw`items-center flex flex-row`}>
              <Typography css={tw`mr-1`} color={colors.PRIMARY_PURPLE_500} variant="subtitle1">
                Copy
              </Typography>
              <DuplicateMinor width={20} fill={colors.PRIMARY_PURPLE_500} />
            </div>
          </Button>
        </div>
        <div css={tw`bg-[#EDEDED] h-[1px] w-full`} />
        {/* <div css={tw`flex flex-row justify-between items-center px-2 py-4`}>
          <div css={tw`flex flex-row items-center`}>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mr-4`}
            >
              <ChatMajor width={20} />
            </div>
            <div>
              <Typography css={tw`mb-0.5 max-w-[140px]`} variant="h4">
                Via phone number (text only)
              </Typography>
              <Typography color={colors.SHADES_GREY_400}>{PHONE}</Typography>
            </div>
          </div>
          <Button
            css={tw`bg-[#F2E5FF] h-[36px] w-[84px] hover:bg-[rgba(226, 208, 244, 1)]`}
            onClick={handleCopyPhone}
          >
            <div css={tw`items-center flex flex-row`}>
              <Typography css={tw`mr-1`} color={colors.PRIMARY_PURPLE_500} variant="subtitle1">
                Copy
              </Typography>
              <DuplicateMinor width={20} fill={colors.PRIMARY_PURPLE_500} />
            </div>
          </Button>
        </div> */}
        <div css={tw`bg-[#EDEDED] h-[1px] w-full`} />
        <Button fullWidth onClick={onClose} variant="contained" css={tw`bg-black mt-6`}>
          <Typography color={colors.GRAY_WHITE} fontWeight={600}>
            Close
          </Typography>
        </Button>
      </div>
    </Modal>
  )
}

export const GetHelpModal = memo(GetHelpModalComponent)
