import React, { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  getContractorPortalDriver,
  getIsContractorPortalDriverInitialized,
} from 'src/selectors/driver'
import { Typography, Button } from '@mui/material'
import tw from 'twin.macro'
import { colors } from 'src/theme'
import { LoadingPage } from '../LoadingPage'
import { useNavigate } from 'react-router-dom'
import { ContractorPortalSecureWrapper } from 'src/components/layout/contractorPortal/ContractorPortalSecureWrapper'
import { ChevronLeftMinor } from '@shopify/polaris-icons'
import {
  getContractorPortalInvoices,
  getContractorPortalInvoicesCount,
} from 'src/selectors/contractorPortal'
import { ContractorPortalInvoiceItem } from 'src/components/contractor-portal/ContractorPortalInvoiceItem'
import { CONTRACTOR_PORTAL_ROUTES } from 'src/constants/routes'
import EmptyImage from 'src/assets/images/empty-invoices.svg'

const ContractorPortalInvoicesPageComponent = () => {
  const navigate = useNavigate()

  const isDriverInitialized = useSelector(getIsContractorPortalDriverInitialized)
  const driver = useSelector(getContractorPortalDriver)
  const invoices = useSelector(getContractorPortalInvoices)
  const invoicesCount = useSelector(getContractorPortalInvoicesCount)

  const handleBack = useCallback(() => {
    navigate(CONTRACTOR_PORTAL_ROUTES.HOME_URL)
  }, [])

  if (!isDriverInitialized) {
    return <LoadingPage />
  }

  return (
    <ContractorPortalSecureWrapper>
      <div css={tw`w-full px-4 pt-20 lg:pt-6 pb-12`}>
        <Button
          css={tw`bg-[#F2E5FF] h-[42px] w-[82px] hover:bg-[rgba(226, 208, 244, 1)]`}
          onClick={handleBack}
        >
          <div css={tw`items-center flex flex-row`}>
            <ChevronLeftMinor fill={colors.PRIMARY_PURPLE_500} width={19} />
            <Typography color={colors.PRIMARY_PURPLE_500} css={tw`[font-weight: 600]`}>
              Back
            </Typography>
          </div>
        </Button>
        <Typography variant="h2" css={tw`mb-2 mt-6`}>
          Your invoices
        </Typography>
        <Typography css={tw`mb-6`} color={colors.GRAY_DARK_COOL}>
          {`${driver?.partner.displayName} • ${invoicesCount} invoices`}
        </Typography>
        {invoices.length ? (
          <div css={tw`bg-white rounded-2xl overflow-hidden`}>
            {invoices.map((item, index) => (
              <ContractorPortalInvoiceItem key={item.id} invoice={item} isFirstItem={index === 0} />
            ))}
          </div>
        ) : (
          <div css={tw`bg-white rounded-2xl p-10 flex flex-col`}>
            <img css={tw`self-center`} src={EmptyImage} />
            <Typography css={tw`self-center mt-4 mb-0.5`} variant="h4">
              This board is empty
            </Typography>
            <Typography css={tw`text-center`} variant="body2" color={colors.SHADES_GREY_400}>
              You haven't received any invoices yet. Check back later for updates.
            </Typography>
          </div>
        )}
      </div>
    </ContractorPortalSecureWrapper>
  )
}

export const ContractorPortalInvoicesPage = memo(ContractorPortalInvoicesPageComponent)
