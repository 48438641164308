import { OnboardingLogout, OnboardingSetDriver } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { OnboardingDriverState } from './types'

const getInitialState = (): OnboardingDriverState => ({
  onboardingDriver: null,
  isOnboardingDriverInitialized: false,
})

export const onboardingDriverReducer = (
  state: OnboardingDriverState = getInitialState(),
  action: Action<any>,
): OnboardingDriverState => {
  switch (action.type) {
    case OnboardingSetDriver.type.SUCCESS:
      return {
        ...state,
        isOnboardingDriverInitialized: true,
        onboardingDriver: action.payload,
      }
    case OnboardingLogout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
