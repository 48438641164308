import React, { memo, useCallback } from 'react'
import tw from 'twin.macro'
import { useSelector } from 'react-redux'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'
import { BGCOnboardingStep } from 'src/interfaces/onboarding'
import { useYardstik } from 'src/hooks/useYardstik'
import { InfoContainer } from '../shared/InfoContainer'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'

interface Props {
  flow: 'onboarding' | 'recollect'
}

const BGCProceedWithYardstikComponent = ({ flow }: Props) => {
  const currentStep = useSelector(
    flow === 'recollect' ? getCurrentRecollectionStep : getCurrentOnboardingStep,
  ) as BGCOnboardingStep

  const { startYardstik, YardstikView } = useYardstik(flow)

  const showYardstik = useCallback(() => {
    startYardstik(currentStep.id)
  }, [currentStep.id])

  return (
    <>
      <InfoContainer
        className={tw`mt-4`}
        actionText="Tap to proceed with Yardstik"
        onClick={showYardstik}
      >
        Please note: If you are only able to upload one document out of the two, please proceed with
        the Yardstik process.
      </InfoContainer>
      <YardstikView />
    </>
  )
}

export const BGCProceedWithYardstik = memo(BGCProceedWithYardstikComponent)
