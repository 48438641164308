import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { OnboardingPublicWrapper } from 'src/components/layout/onboarding/OnboardingPublicWrapper'
import { SignUpForm } from 'src/components/auth/sign-up-form/SignUpForm'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { ONBOARDING_ROUTES } from 'src/constants/routes'
import { useQuery } from 'src/utils/hooks/useQuery'
import { ChevronRightMinor } from '@shopify/polaris-icons'
import { useSelector } from 'react-redux'
import { getPartnerPublicInfo } from 'src/selectors/partner'

export const OnboardingSignUpPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { getQuery, getQueryAsArray } = useQuery()

  const partnerMarketId = getQuery('partnerMarketId')
  const onboardingTemplateId = getQuery('onboardingTemplateId')
  const referral = getQuery('referral')
  const tagNames = getQueryAsArray('tags') || []

  const partner = useSelector(getPartnerPublicInfo)

  const handleSuccess = (phone: string) => {
    navigate(`/onboarding/${params.partnerName}/enter-code`, { state: { phone } })
  }

  const handleContinue = () => {
    navigate(`/onboarding/${params.partnerName}/sign-in`, { state: { isContinue: true } })
  }

  if (!params?.partnerName || !partnerMarketId || !onboardingTemplateId) {
    return <Navigate to={ONBOARDING_ROUTES.NOT_FOUND_URL} />
  }

  return (
    <OnboardingPublicWrapper
      header={
        <div
          css={tw`w-full bg-[rgba(242, 229, 255, 1)] hover:bg-[rgba(226, 208, 244, 1)] flex flex-row justify-between items-center p-4 cursor-pointer`}
          onClick={handleContinue}
        >
          <div>
            <Typography color={colors.PRIMARY_PURPLE_500}>Already started onboarding?</Typography>
            <Typography color={colors.PRIMARY_PURPLE_500} variant="h4">
              Tap to continue
            </Typography>
          </div>
          <ChevronRightMinor width={20} fill={colors.PRIMARY_PURPLE_500} />
        </div>
      }
    >
      <Typography variant="h2" css={tw`mb-2 mt-4`}>
        {`Begin your onboarding${partner?.displayName ? ` with ${partner?.displayName}` : ''}`}
      </Typography>
      <Typography css={tw`mb-6`} color={colors.GRAY_DARK_COOL}>
        You've been invited to onboard as an independent contractor. Please provide basic
        information to create an account and upload necessary documents for verification.
      </Typography>
      <SignUpForm
        partnerName={params.partnerName}
        referral={referral}
        partnerMarketId={partnerMarketId}
        onboardingTemplateId={onboardingTemplateId}
        tagNames={tagNames}
        onSuccess={handleSuccess}
      />
    </OnboardingPublicWrapper>
  )
}
