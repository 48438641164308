import React, { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  getContractorPortalDriver,
  getIsContractorPortalDriverInitialized,
} from 'src/selectors/driver'
import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { colors } from 'src/theme'
import { LoadingPage } from '../LoadingPage'
import { useNavigate } from 'react-router-dom'
import { CONTRACTOR_PORTAL_ROUTES } from 'src/constants/routes'
import { ContractorPortalSecureWrapper } from 'src/components/layout/contractorPortal/ContractorPortalSecureWrapper'
import { ChevronRightMinor, ReceiptMajor } from '@shopify/polaris-icons'
import { getContractorPortalInvoicesCount } from 'src/selectors/contractorPortal'
import PoweredLogo from 'src/assets/images/poweredby.svg'
import Background from 'src/assets/images/background-pattern.svg'

const ContractorPortalHomePageComponent = () => {
  const navigate = useNavigate()

  const isDriverInitialized = useSelector(getIsContractorPortalDriverInitialized)
  const driver = useSelector(getContractorPortalDriver)
  const invoicesCount = useSelector(getContractorPortalInvoicesCount)

  const handleOpenInvoices = useCallback(() => {
    navigate(CONTRACTOR_PORTAL_ROUTES.INVOICES_URL)
  }, [])

  const handleClickLogo = useCallback(() => {
    window.open('https://www.gigsafe.com/')
  }, [])

  if (!isDriverInitialized) {
    return <LoadingPage />
  }

  return (
    <ContractorPortalSecureWrapper>
      <div css={tw`flex flex-2 relative w-full justify-center items-center lg:flex-1 bg-[#440B7C]`}>
        <img src={Background} css={tw`w-full h-full opacity-10`} />
        <div css={tw`absolute bottom-[-40px] left-4 right-4`}>
          <Typography variant="overline" color={colors.GRAY_WHITE}>
            WELCOME BACK
          </Typography>
          <Typography css={tw`mb-4 mt-0.5`} variant="h1" color={colors.GRAY_WHITE}>
            {driver?.name}
          </Typography>
          <div
            css={tw`p-4 flex rounded-2xl cursor-pointer items-center bg-white justify-between hover:bg-[#EDEDED] active:bg-[#DAD9E0]`}
            onClick={handleOpenInvoices}
          >
            <div css={tw`flex items-center`}>
              <div
                css={tw`h-11 w-11 rounded-[22px] items-center justify-center flex bg-[#F6F6F6] mr-4`}
              >
                <ReceiptMajor width={20} height={20} />
              </div>
              <div>
                <Typography variant="h4" css={tw`mb-0.5`}>
                  Invoices
                </Typography>
                <Typography color="#847E96" css={tw`mb-0.5`}>
                  {`${invoicesCount} invoices`}
                </Typography>
              </div>
            </div>
            <ChevronRightMinor fill="black" width={20} height={20} />
          </div>
        </div>
      </div>
      <div css={tw`flex flex-1 pb-8 items-end bg-[#F6F6F6]`}>
        <div css={tw`cursor-pointer w-full`} onClick={handleClickLogo}>
          <img src={PoweredLogo} />
        </div>
      </div>
    </ContractorPortalSecureWrapper>
  )
}

export const ContractorPortalHomePage = memo(ContractorPortalHomePageComponent)
