import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { BasicTextField, BasicTextFieldProps } from '../BasicTextField'

export const FormikTextField = <T extends unknown>({
  name,
  label,
  ...rest
}: BasicTextFieldProps<T>) => {
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } = useFormikContext<T>()

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]')

    if (el?.parentElement) {
      el?.parentElement?.scrollIntoView({ block: 'center' })

      return
    }

    el?.scrollIntoView()
  }, [isSubmitting])

  const isTouched = touched[name] as boolean
  const error = errors[name] as string | undefined

  return (
    <BasicTextField
      name={name}
      value={values[name]}
      error={Boolean(isTouched && error)}
      helperText={isTouched && error}
      placeholder={label}
      label={label}
      onChange={handleChange(name)}
      onBlur={handleBlur(name as string)}
      {...rest}
    />
  )
}
