import { memo, useCallback } from 'react'
import { Typography } from '@mui/material'
import { ContractorPortalPartner } from 'src/interfaces/contractorPortal'
import tw from 'twin.macro'
import { ChevronRightMinor } from '@shopify/polaris-icons'

interface Props {
  partner: ContractorPortalPartner
  onClick: (partner: ContractorPortalPartner) => void
}

const ContractorPortalPartnerItemComponent = ({ partner, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(partner)
  }, [partner, onClick])

  return (
    <div
      css={tw`py-4 flex items-center bg-white border-0 border-t border-solid border-[#EDEDED] justify-between hover:bg-[#EDEDED] active:bg-[#DAD9E0]`}
      onClick={handleClick}
    >
      <div css={tw`flex items-center`}>
        <div>
          <Typography variant="h4" css={tw`mb-0.5`}>
            {partner.displayName}
          </Typography>
          <Typography color="#847E96" css={tw`mb-0.5`}>
            {`${partner.invoicesCount} invoices`}
          </Typography>
        </div>
      </div>
      <ChevronRightMinor fill="black" width={20} height={20} />
    </div>
  )
}

export const ContractorPortalPartnerItem = memo(ContractorPortalPartnerItemComponent)
