import { OnboardingStepDataMapItem } from 'src/interfaces/onboarding'
import DriverIcon from 'src/assets/icons/driver-front.svg'
import BGCIcon from 'src/assets/icons/bgc.svg'
import BookmarkIcon from 'src/assets/icons/bookmark.svg'
import {
  BlogMajor,
  CustomersMajor,
  FinancesMajor,
  JobsMajor,
  NoteMajor,
  PageDownMajor,
  QuestionMarkMajor,
  SecureMajor,
  ShipmentMajor,
  TextBlockMajor,
} from '@shopify/polaris-icons'

export enum OnboardingStatus {
  Created = 'created',
  InProgress = 'in_progress',
  WaitingForInternalApproval = 'waiting_for_internal_approval',
  WaitingForApproval = 'waiting_for_approval',
  Completed = 'completed',
  Failed = 'failed', // Applies only for BG check

  Archived = 'archived',
}

export enum OnboardingStepType {
  Questionnaire = 'questionnaire',
  Form = 'form',
  DriverLicense = 'driver_license',
  VehicleInsurance = 'vehicle_and_insurance',
  InsuranceDeclaration = 'insurance_amount_declaration',
  VehicleRegistration = 'vehicle_registration',
  Vaccination = 'vaccination_information',
  BGC = 'background_check',
  PaymentMethod = 'payment_method',
  StitchPayment = 'stitch_payment',
  InsuranceBeneficiary = 'insurance_beneficiary',
  SignContract = 'sign_contract',
  Training = 'training',
  MVR = 'mvr_upload',
  EIN = 'business_entity',
}

export const ONBOARDING_STEP_TIME_TO_COMPLETE = {
  [OnboardingStepType.Questionnaire]: 2,
  [OnboardingStepType.DriverLicense]: 2,
  [OnboardingStepType.VehicleInsurance]: 3,
  [OnboardingStepType.InsuranceDeclaration]: 1,
  [OnboardingStepType.VehicleRegistration]: 1,
  [OnboardingStepType.Vaccination]: 1,
  [OnboardingStepType.BGC]: 3,
  [OnboardingStepType.PaymentMethod]: 3,
  [OnboardingStepType.StitchPayment]: 3,
  [OnboardingStepType.InsuranceBeneficiary]: 1,
  [OnboardingStepType.SignContract]: 3,
  [OnboardingStepType.Training]: 1,
  [OnboardingStepType.MVR]: 2,
  [OnboardingStepType.Form]: 2,
  [OnboardingStepType.EIN]: 2,
}

export const onboardingStepDataMap: {
  [key in OnboardingStepType]: (options: { optional: boolean }) => OnboardingStepDataMapItem
} = {
  [OnboardingStepType.Questionnaire]: () => ({
    icon: <QuestionMarkMajor width={20} />,
    title: 'Questionnaire',
    text: "Let's see if you're a good fit.",
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.Questionnaire],
  }),
  [OnboardingStepType.DriverLicense]: () => ({
    icon: <img src={DriverIcon} />,
    title: "Driver's License",
    text: "Provide your basic details and upload your driver's license for swift verification.",
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.DriverLicense],
  }),
  [OnboardingStepType.VehicleInsurance]: () => ({
    icon: <ShipmentMajor width={20} />,
    title: 'Vehicle and insurance',
    text: 'Input your vehicle details and upload your insurance card.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.VehicleInsurance],
  }),
  [OnboardingStepType.InsuranceDeclaration]: () => ({
    icon: <NoteMajor height={20} />,
    title: 'Insurance declaration page',
    text: 'Submit your detailed Insurance Declarations Page with coverage limits.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.InsuranceDeclaration],
  }),
  [OnboardingStepType.VehicleRegistration]: () => ({
    icon: <TextBlockMajor height={20} width={20} />,
    title: 'Vehicle registration',
    text: 'Submit your vehicle registration by uploading a photo of it.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.VehicleRegistration],
  }),
  [OnboardingStepType.Vaccination]: () => ({
    icon: <SecureMajor height={20} width={20} />,
    title: 'Vaccination information',
    text: 'Enter details about your most recent vaccination.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.Vaccination],
  }),
  [OnboardingStepType.BGC]: () => ({
    icon: <img src={BGCIcon} />,
    title: 'Background check',
    text: 'Run through our partner, Yardstik.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.BGC],
  }),
  [OnboardingStepType.PaymentMethod]: () => ({
    icon: <FinancesMajor height={20} width={20} />,
    title: 'Get paid!',
    text: 'Choose your preferred way to get paid!',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.PaymentMethod],
  }),
  [OnboardingStepType.StitchPayment]: () => ({
    icon: <FinancesMajor height={20} width={20} />,
    title: 'Get paid!',
    text: 'Choose your preferred way to get paid!',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.StitchPayment],
  }),
  [OnboardingStepType.InsuranceBeneficiary]: () => ({
    icon: <CustomersMajor height={18} width={16} />,
    title: 'Insurance beneficiary',
    text: 'Set your beneficiary for insurance policies.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.InsuranceBeneficiary],
  }),
  [OnboardingStepType.SignContract]: () => ({
    icon: <BlogMajor height={20} width={19} />,
    title: 'Sign the contract',
    text: 'Review the contract and complete the signing process.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.SignContract],
  }),
  [OnboardingStepType.Training]: () => ({
    icon: <img src={BookmarkIcon} />,
    title: 'Get Certified',
    text: 'Get certified by following the provided instructions.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.Training],
  }),
  [OnboardingStepType.MVR]: ({ optional }) => ({
    icon: <PageDownMajor width={20} />,
    title: 'Upload MVR' + (optional ? ' (optional)' : ''),
    text: 'Submit your detailed motor vehicle record.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.MVR],
  }),
  [OnboardingStepType.Form]: () => ({
    icon: <QuestionMarkMajor width={20} />,
    title: 'Questionnaire',
    text: "Let's see if you're a good fit.",
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.Form],
  }),
  [OnboardingStepType.EIN]: () => ({
    icon: <JobsMajor width={20} />,
    title: 'Business entity',
    text: 'Provide business information, if applicable.',
    finishTime: ONBOARDING_STEP_TIME_TO_COMPLETE[OnboardingStepType.EIN],
  }),
}
