import * as Yup from 'yup'
import { parsePhoneNumber } from 'libphonenumber-js'

Yup.addMethod(Yup.string, 'validatePhone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this

    if (!value || value.length < 10) {
      return createError({ path, message: errorMessage })
    }

    const parsedPhoneNumber = parsePhoneNumber(value, 'US')

    return (
      !!parsedPhoneNumber?.formatInternational() || createError({ path, message: errorMessage })
    )
  })
})

export const SignUpSchema = Yup.object().shape({
  // @ts-ignore
  phone: Yup.string().required('Phone number is required').validatePhone('Phone number is invalid'),
  email: Yup.string().required('Email address is required').email('Email is invalid'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  referral: Yup.string(),
})
