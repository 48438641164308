import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  OnboardingUploadVaccination,
  OnboardingFetchDriver,
  OnboardingUploadInsuranceBeneficiary,
  OnboardingUploadTraining,
  OnboardingUploadVehicleInsurance,
  OnboardingUploadInsuranceDeclaration,
  OnboardingUploadVehicleRegistration,
  OnboardingUploadQuestionnaire,
  OnboardingUploadMVR,
  OnboardingUploadForm,
  OnboardingUploadBGC,
  OnboardingUploadEIN,
} from 'src/constants/actionTypes'
import { ApiService, callOnboardingSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { InsuranceBeneficiaryRelation } from 'src/constants/insuranceBeneficiary'
import { UploadFormResponse } from 'src/interfaces/collectData'

interface UploadFormRequest {
  data: Array<{ id: string; value: any }>
  files: Array<{ id: string; value: File }>
  stepId: string
}

function* onUploadForm(action: AsyncAction<UploadFormRequest>) {
  const { data, stepId, files } = action.payload

  const formData = new FormData()

  if (files.length) {
    files.forEach((item) => {
      formData.append('files', item.value, item.id)
    })
  }

  formData.append(
    'body',
    JSON.stringify({
      data,
      stepId,
    }),
  )

  try {
    const { payload }: { payload: UploadFormResponse } = yield call(
      callOnboardingSecureApi,
      Endpoints.OnboardingUploadForm,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
        body: formData,
        resetContentType: true,
      },
    )

    yield put(OnboardingUploadForm.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload form'

    yield put(OnboardingUploadForm.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadQuestionnaireRequest {
  answers: Array<{ id: string; type: 'boolean'; value: boolean }>
  stepId: string
}

function* onUploadQuestionnaire(action: AsyncAction<UploadQuestionnaireRequest>) {
  const { answers, stepId } = action.payload

  try {
    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadQuestionnaire, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        list: answers,
        stepId,
      },
    })

    yield put(OnboardingUploadQuestionnaire.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload questionnaire'

    yield put(OnboardingUploadQuestionnaire.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadVehicleInsuranceRequest {
  stepId: string
  file: File
  policyDocument?: File
  type: string
  year?: string | number
  carMake?: string
  carModel?: string
  vin?: string | number
}

function* onUploadVehicleInsurance(action: AsyncAction<UploadVehicleInsuranceRequest>) {
  const { file, type, year, carMake, carModel, vin, stepId, policyDocument } = action.payload

  try {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('type', type)
    formData.append('stepId', stepId)

    if (policyDocument) {
      formData.append('policyDocument', policyDocument)
    }
    if (year) {
      formData.append('year', String(year))
    }
    if (carMake) {
      formData.append('carMake', carMake)
    }
    if (carModel) {
      formData.append('carModel', carModel)
    }
    if (vin) {
      formData.append('vin', String(vin))
    }

    formData.forEach((value, key) => console.log(value, key))

    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadVehicleInsurance, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(OnboardingUploadVehicleInsurance.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload vehicle insurance'

    yield put(OnboardingUploadVehicleInsurance.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadInsuranceDeclarationRequest {
  file: File
  stepId: string
}

function* onUploadInsuranceDeclaration(action: AsyncAction<UploadInsuranceDeclarationRequest>) {
  const { file, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('stepId', stepId)

    formData.forEach((value, key) => console.log(value, key))

    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadInsuranceDeclaration, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(OnboardingUploadInsuranceDeclaration.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload insurance declaration'

    yield put(OnboardingUploadInsuranceDeclaration.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadVehicleRegistrationRequest {
  file: File
  stepId: string
}

function* onUploadVehicleRegistration(action: AsyncAction<UploadVehicleRegistrationRequest>) {
  const { file, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('stepId', stepId)

    formData.forEach((value, key) => console.log(value, key))

    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadVehicleRegistration, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(OnboardingUploadVehicleRegistration.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload vehicle registration'

    yield put(OnboardingUploadVehicleRegistration.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadVaccinationRequest {
  brand?: string
  date?: string // 2024/12/10
  isVaccinated: boolean
  stepId: string
}

function* onUploadVaccination(action: AsyncAction<UploadVaccinationRequest>) {
  const { brand, date, isVaccinated, stepId } = action.payload

  try {
    const body: UploadVaccinationRequest = {
      isVaccinated,
      stepId,
    }

    if (isVaccinated && brand && date) {
      body.brand = brand
      body.date = date
    }

    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadVaccination, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body,
    })

    yield put(OnboardingUploadVaccination.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload vaccination'

    yield put(OnboardingUploadVaccination.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadInsuranceBeneficiaryRequest {
  firstName: string
  lastName: string
  relation: InsuranceBeneficiaryRelation
  stepId: string
}

function* onUploadInsuranceBeneficiary(action: AsyncAction<UploadInsuranceBeneficiaryRequest>) {
  const { firstName, lastName, relation, stepId } = action.payload

  try {
    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadInsuranceBeneficiary, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        firstName,
        lastName,
        relation,
        stepId,
      },
    })

    yield put(OnboardingUploadInsuranceBeneficiary.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload insurance beneficiary'

    yield put(OnboardingUploadInsuranceBeneficiary.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadTrainingRequest {
  files: Array<{ fieldName: string; file: File }>
  stepId: string
}

function* onUploadTraining(action: AsyncAction<UploadTrainingRequest>) {
  const { files, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('stepId', stepId)

    files.forEach((item) => {
      formData.append('files', item.file, item.fieldName)
    })

    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadTraining, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(OnboardingUploadTraining.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload certificates'

    yield put(OnboardingUploadTraining.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadMVRRequest {
  file?: File
  stepId: string
}

function* onUploadMVR(action: AsyncAction<UploadMVRRequest>) {
  const { file, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('stepId', stepId)

    if (file) {
      formData.append('file', file)
    }

    formData.forEach((value, key) => console.log(value, key))

    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadMVR, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(OnboardingUploadMVR.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload mvr'

    yield put(OnboardingUploadMVR.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadBGCRequest {
  files: Array<{ file: File; name: string }>
  stepId: string
}

function* onUploadBGC(action: AsyncAction<UploadBGCRequest>) {
  const { files, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('stepId', stepId)

    if (files.length) {
      files.forEach((item) => {
        formData.append('files', item.file, item.name)
      })
    }

    formData.forEach((value, key) => console.log(value, key))

    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadBGC, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(OnboardingUploadBGC.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload bgc'

    yield put(OnboardingUploadBGC.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadEINRequest {
  stepId: string
  file?: File
  ein?: string | number
  businessName?: string
  streetAddress?: string
  city?: string
  state?: string
  zipCode?: string | number
  isBusinessEntityFlow?: boolean
}

function* onUploadEIN(action: AsyncAction<UploadEINRequest>) {
  const {
    file,
    ein,
    zipCode,
    stepId,
    businessName,
    streetAddress,
    city,
    state,
    isBusinessEntityFlow,
  } = action.payload

  try {
    const formData = new FormData()

    formData.append('stepId', stepId)
    formData.append('isBusinessEntityFlow', JSON.stringify(isBusinessEntityFlow))

    if (file) {
      formData.append('file', file)
    }
    if (ein) {
      formData.append('ein', String(ein))
    }
    if (zipCode) {
      formData.append('zipCode', String(zipCode))
    }
    if (businessName) {
      formData.append('businessName', businessName)
    }
    if (streetAddress) {
      formData.append('streetAddress', streetAddress)
    }
    if (city) {
      formData.append('city', city)
    }
    if (state) {
      formData.append('state', state)
    }

    formData.forEach((value, key) => console.log(value, key))

    yield call(callOnboardingSecureApi, Endpoints.OnboardingUploadEIN, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(OnboardingUploadEIN.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload ein'

    yield put(OnboardingUploadEIN.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onboardingDocumentsSaga() {
  yield takeEvery(OnboardingUploadQuestionnaire.type.REQUEST, onUploadQuestionnaire)
  yield takeEvery(OnboardingUploadForm.type.REQUEST, onUploadForm)
  yield takeEvery(OnboardingUploadVehicleInsurance.type.REQUEST, onUploadVehicleInsurance)
  yield takeEvery(OnboardingUploadInsuranceDeclaration.type.REQUEST, onUploadInsuranceDeclaration)
  yield takeEvery(OnboardingUploadVehicleRegistration.type.REQUEST, onUploadVehicleRegistration)
  yield takeEvery(OnboardingUploadVaccination.type.REQUEST, onUploadVaccination)
  yield takeEvery(OnboardingUploadInsuranceBeneficiary.type.REQUEST, onUploadInsuranceBeneficiary)
  yield takeEvery(OnboardingUploadTraining.type.REQUEST, onUploadTraining)
  yield takeEvery(OnboardingUploadMVR.type.REQUEST, onUploadMVR)
  yield takeEvery(OnboardingUploadBGC.type.REQUEST, onUploadBGC)
  yield takeEvery(OnboardingUploadEIN.type.REQUEST, onUploadEIN)
}
