import { useMemo } from 'react'
import { DocusignSigningField } from 'src/interfaces/docusign'
import { DocusignFieldsFormModalInput } from './DocusignFieldsFormModalInput'
import { DocusignFieldTabLabel, TaxClassificationType } from 'src/constants/docusign'
import { useFormikContext } from 'formik'
import { DocusignFieldsFormModalValues } from './DocusignFieldsFormModal.interface'

interface Props {
  fields: Array<DocusignSigningField>
}

export const DocusignFieldsFormModalInputList = ({ fields }: Props) => {
  const { values } = useFormikContext<DocusignFieldsFormModalValues>()

  const orderedFields = useMemo<Array<DocusignSigningField | undefined>>(() => {
    const otherInsurancePlan = fields.find(
      (item) => item.tabLabel === DocusignFieldTabLabel.otherInsurancePlan,
    )
    const insuranceCarrier = fields.find(
      (item) => item.tabLabel === DocusignFieldTabLabel.insuranceCarrier,
    )
    const eSignatureAck = fields.find(
      (item) => item.tabLabel === DocusignFieldTabLabel.eSignatureAck,
    )

    const taxClassificationFields: Array<DocusignSigningField> = []
    const taxClassificationType = fields.find(
      (item) => item.tabLabel === DocusignFieldTabLabel.taxClassificationType,
    )

    if (taxClassificationType) {
      taxClassificationFields.push(taxClassificationType)

      const taxClassificationTypeValue = values[DocusignFieldTabLabel.taxClassificationType]

      if (taxClassificationTypeValue === TaxClassificationType.Llc) {
        const llcType = fields.find((item) => item.tabLabel === DocusignFieldTabLabel.llcType)
        if (llcType) {
          taxClassificationFields.push(llcType)
        }
      }

      if (taxClassificationTypeValue === TaxClassificationType.Other) {
        const otherTaxClassification = fields.find(
          (item) => item.tabLabel === DocusignFieldTabLabel.otherTaxClassification,
        )
        if (otherTaxClassification) {
          taxClassificationFields.push(otherTaxClassification)
        }
      }

      const otherForeignPartners = fields.find(
        (item) => item.tabLabel === DocusignFieldTabLabel.otherForeignPartners,
      )
      if (otherForeignPartners) {
        taxClassificationFields.push(otherForeignPartners)
      }

      const exemptPayeeCode = fields.find(
        (item) => item.tabLabel === DocusignFieldTabLabel.exemptPayeeCode,
      )
      if (exemptPayeeCode) {
        taxClassificationFields.push(exemptPayeeCode)
      }

      const exemptionFatcaCode = fields.find(
        (item) => item.tabLabel === DocusignFieldTabLabel.exemptionFatcaCode,
      )
      if (exemptionFatcaCode) {
        taxClassificationFields.push(exemptionFatcaCode)
      }
    }

    return [
      ...fields.filter(
        (item) =>
          ![
            DocusignFieldTabLabel.otherInsurancePlan,
            DocusignFieldTabLabel.insuranceCarrier,
            DocusignFieldTabLabel.eSignatureAck,
            DocusignFieldTabLabel.taxClassificationType,
            DocusignFieldTabLabel.llcType,
            DocusignFieldTabLabel.exemptPayeeCode,
            DocusignFieldTabLabel.exemptionFatcaCode,
            DocusignFieldTabLabel.mmsOregonAddress,
            DocusignFieldTabLabel.otherTaxClassification,
            DocusignFieldTabLabel.otherForeignPartners,
          ].includes(item.tabLabel),
      ),
      otherInsurancePlan,
      insuranceCarrier,
      eSignatureAck,
      ...taxClassificationFields,
    ]
  }, [fields, values])

  return (
    <div>
      {orderedFields.map((item) => (item ? <DocusignFieldsFormModalInput field={item} /> : null))}
    </div>
  )
}
