import { OnboardingDriver } from 'src/interfaces/onboarding'
import { State } from '../reducers/types'
import { ContractorPortalDriver } from 'src/interfaces/contractorPortal'

export const getRecollectionDriver = (state: State): OnboardingDriver | null =>
  state.recollectionDriver.recollectionDriver
export const getIsRecollectionDriverInitialized = (state: State): boolean =>
  state.recollectionDriver.isRecollectionDriverInitialized

export const getOnboardingDriver = (state: State): OnboardingDriver | null =>
  state.onboardingDriver.onboardingDriver
export const getIsOnboardingDriverInitialized = (state: State): boolean =>
  state.onboardingDriver.isOnboardingDriverInitialized

export const getContractorPortalDriver = (state: State): ContractorPortalDriver | null =>
  state.contractorPortalDriver.contractorPortalDriver
export const getIsContractorPortalDriverInitialized = (state: State): boolean =>
  state.contractorPortalDriver.isContractorPortalDriverInitialized
