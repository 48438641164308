import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  RecollectionSignInEnterCode,
  RecollectionSignInResendCode,
  OnboardingSignInEnterCode,
  OnboardingSignInResendCode,
  ContractorPortalSignInEnterCode,
  ContractorPortalSignInResendCode,
} from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { EnterCodeFormValues } from './EnterCodeForm.interface'
import { EnterCodeSchema } from './EnterCodeForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { ResendCode } from 'src/components/shared/ResendCode'

interface Props {
  partnerName?: string
  partnerId?: string
  phone: string
  platform: 'recollection' | 'onboarding' | 'contractorPortal'
}

const initialValues: EnterCodeFormValues = {
  code: '',
}

export const EnterCodeForm = ({ partnerName, partnerId, phone, platform }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: EnterCodeFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      if (platform === 'recollection') {
        await createAsyncAction(
          dispatch,
          RecollectionSignInEnterCode.request({ ...values, phone, partnerName }),
        )
      }
      if (platform === 'onboarding') {
        await createAsyncAction(
          dispatch,
          OnboardingSignInEnterCode.request({ ...values, phone, partnerName }),
        )
      }
      if (platform === 'contractorPortal') {
        await createAsyncAction(
          dispatch,
          ContractorPortalSignInEnterCode.request({ ...values, phone, partnerId }),
        )
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleResend = async () => {
    try {
      if (platform === 'recollection') {
        await createAsyncAction(
          dispatch,
          RecollectionSignInResendCode.request({ phone, partnerName }),
        )
      }
      if (platform === 'onboarding') {
        await createAsyncAction(
          dispatch,
          OnboardingSignInResendCode.request({ phone, partnerName }),
        )
      }
      if (platform === 'contractorPortal') {
        await createAsyncAction(dispatch, ContractorPortalSignInResendCode.request({ phone }))
      }
    } catch (err: any) {}
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={EnterCodeSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <FormikTextField name="code" css={tw`w-full mb-6`} placeholder="XX-XX-XX" />
          <FormErrorMessage css={tw`mb-6`} error={error} />
          <LoadingButton
            css={tw`w-full mb-6`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            <span>Continue</span>
          </LoadingButton>
          <ResendCode onResend={handleResend} />
        </div>
      )}
    </Formik>
  )
}
