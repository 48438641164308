import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction, Action } from 'src/utils/reduxUtils'
import {
  ContractorPortalFetchDriver,
  ContractorPortalSignInPhone,
  ContractorPortalSignInEnterCode,
  ContractorPortalSignInResendCode,
  ContractorPortalSetAuthToken,
  ContractorPortalLogout,
  ContractorPortalSetDriver,
  ContractorPortalSwitchPartner,
} from 'src/constants/actionTypes'
import { ApiService, callApi, callContractorPortalSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { authService } from 'src/services/AuthService'

interface SignInPhoneRequest {
  phone: string
}

function* onSignInPhone(action: AsyncAction<SignInPhoneRequest>) {
  const { phone } = action.payload

  try {
    const { payload }: { payload: { smsSend: boolean } } = yield call(
      callApi,
      Endpoints.ContractorPortalSignInPhone,
      {
        method: 'POST',
        body: {
          phone,
        },
        apiService: ApiService.Paraworks,
      },
    )

    if (payload?.smsSend === false) {
      throw {
        payload: {
          message: 'No partner driver found with the provided phone number',
        },
      }
    }

    yield put(ContractorPortalSignInPhone.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to signIn'

    yield put(ContractorPortalSignInPhone.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SignInEnterCodeRequest {
  code: string
  phone: string
  partnerId: string
}

interface SignInEnterCodePayload {
  token: string
}

function* onSignInEnterCode(action: AsyncAction<SignInEnterCodeRequest>) {
  const { code, phone, partnerId } = action.payload

  try {
    const { payload }: { payload: SignInEnterCodePayload } = yield call(
      callApi,
      Endpoints.ContractorPortalSignInEnterCode,
      {
        method: 'POST',
        body: {
          code,
          phone,
          partnerId,
        },
        apiService: ApiService.Paraworks,
      },
    )

    yield put(ContractorPortalSignInEnterCode.success())
    yield put(ContractorPortalSetAuthToken.request(payload.token))
    yield put(ContractorPortalFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to sign in'

    yield put(ContractorPortalSignInEnterCode.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SwitchPartnerRequest {
  partnerId: string
}

interface SwitchPartnerPayload {
  token: string
}

function* onSwitchPartner(action: AsyncAction<SwitchPartnerRequest>) {
  const { partnerId } = action.payload

  try {
    const { payload }: { payload: SwitchPartnerPayload } = yield call(
      callContractorPortalSecureApi,
      Endpoints.ContractorPortalSwitchPartner,
      {
        method: 'POST',
        body: {
          partnerId,
        },
        apiService: ApiService.Paraworks,
      },
    )

    yield put(ContractorPortalSwitchPartner.success())
    yield put(ContractorPortalSetAuthToken.request(payload.token))
    yield put(ContractorPortalFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to switch partner'

    yield put(ContractorPortalSwitchPartner.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onSetDriverToken(action: Action<string>) {
  const authToken = action.payload

  yield put(ContractorPortalSetAuthToken.success(authToken))

  authService.setStoredContractorPortalAuthToken(authToken)
}

function* onLogout() {
  yield put(ContractorPortalSetDriver.request(null))
  yield put(ContractorPortalSetAuthToken.request(null))
  yield put(ContractorPortalLogout.success())
}

export function* contractorPortalAuthSaga() {
  yield takeEvery(
    [ContractorPortalSignInPhone.type.REQUEST, ContractorPortalSignInResendCode.type.REQUEST],
    onSignInPhone,
  )
  yield takeEvery(ContractorPortalSignInEnterCode.type.REQUEST, onSignInEnterCode)
  yield takeEvery(ContractorPortalSwitchPartner.type.REQUEST, onSwitchPartner)
  yield takeEvery(ContractorPortalSetAuthToken.type.REQUEST, onSetDriverToken)
  yield takeEvery(ContractorPortalLogout.type.REQUEST, onLogout)
}
