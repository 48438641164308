import { memo, useEffect } from 'react'
import { useAsyncDispatchWithErrorToast } from './hooks/useAsyncDispatchWithErrorToast'
import { ContractorPortalFetchDriver, ContractorPortalFetchPartners } from './constants/actionTypes'
import Intercom from '@intercom/messenger-js-sdk'
import { ContractorPortalDriver } from './interfaces/contractorPortal'

export const ContractorPortalSecureHooksContainer = memo(() => {
  const { asyncDispatchWithErrorToast } = useAsyncDispatchWithErrorToast('onboarding')

  useEffect(() => {
    const initUser = async () => {
      try {
        const user = await asyncDispatchWithErrorToast<ContractorPortalDriver>(
          ContractorPortalFetchDriver.request(),
        )

        if (user) {
          await asyncDispatchWithErrorToast(
            ContractorPortalFetchPartners.request({ phone: user.phone }),
          )
        }

        if (process.env.REACT_APP_INTERCOM_APP_ID) {
          Intercom({
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            user_id: user?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
            name: user?.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
            // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
          })
        }
      } catch {}
    }

    initUser()
  }, [])

  return null
})
