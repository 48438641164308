import { ContractorPortalLogout, ContractorPortalSetAuthToken } from 'src/constants/actionTypes'
import { authService } from 'src/services/AuthService'
import { Action } from 'src/utils/reduxUtils'
import { ContractorPortalAuthState } from './types'

const getInitialState = (): ContractorPortalAuthState => ({
  contractorPortalAuthToken: authService.getStoredContractorPortalAuthToken(),
})

export const contractorPortalAuthReducer = (
  state: ContractorPortalAuthState = getInitialState(),
  action: Action<any>,
): ContractorPortalAuthState => {
  switch (action.type) {
    case ContractorPortalSetAuthToken.type.SUCCESS:
      return {
        ...state,
        contractorPortalAuthToken: action.payload,
      }
    case ContractorPortalLogout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
