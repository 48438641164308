import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useQuery = () => {
  const { search } = useLocation()

  const query = useMemo(() => new URLSearchParams(search.replace(/%2C/g, '§§')), [search])

  const getQuery = useCallback(
    (param: string) => {
      const results = query.get(param)

      return results?.replace(/§§/g, ',')
    },
    [query],
  )

  const getQueryAsArray = useCallback(
    (param: string) => {
      const results = query.get(param)

      return results?.split(',').map((item) => item.replace(/§§/g, ','))
    },
    [query],
  )

  return { getQuery, getQueryAsArray }
}
