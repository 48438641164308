import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { ContractorPortalFetchPartners } from 'src/constants/actionTypes'
import { ApiService, callApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { ContractorPortalPartner } from 'src/interfaces/contractorPortal'

interface FetchPartnersRequest {
  phone: string
}

function* onFetchPartners(action: AsyncAction<FetchPartnersRequest, ContractorPortalPartner>) {
  try {
    const { phone } = action.payload

    const { payload }: { payload: ContractorPortalPartner } = yield call(
      callApi,
      Endpoints.ContractorPortalFetchPartners,
      {
        apiService: ApiService.Paraworks,
        query: {
          phone: phone.replace('+', '%2B'),
        },
      },
    )

    yield put(ContractorPortalFetchPartners.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch partners'

    yield put(ContractorPortalFetchPartners.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* contractorPortalPartnersSaga() {
  yield takeEvery(ContractorPortalFetchPartners.type.REQUEST, onFetchPartners)
}
