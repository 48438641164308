import { call, put, takeEvery } from 'redux-saga/effects'
import { Action, AsyncAction } from 'src/utils/reduxUtils'
import {
  ContractorPortalFetchDriver,
  ContractorPortalFetchInvoices,
  ContractorPortalSetDriver,
} from 'src/constants/actionTypes'
import { ApiService, callContractorPortalSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import * as Sentry from '@sentry/react'
import { ContractorPortalDriver } from 'src/interfaces/contractorPortal'

function* onFetchDriver(action: AsyncAction<ContractorPortalDriver>) {
  try {
    const { payload }: { payload: ContractorPortalDriver } = yield call(
      callContractorPortalSecureApi,
      Endpoints.ContractorPortalFetchDriver,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(ContractorPortalFetchDriver.success())
    yield put(ContractorPortalSetDriver.success(payload))

    yield put(ContractorPortalFetchInvoices.request({ partnerId: payload.partner.id }))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch driver'

    yield put(ContractorPortalFetchDriver.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onSetDriverSuccess(action: Action<ContractorPortalDriver | null>) {
  const driver = action.payload

  if (driver) {
    Sentry.setUser({ id: driver.id })
  } else {
    Sentry.setUser(null)
  }
}

export function* contractorPortalContractorSaga() {
  yield takeEvery(ContractorPortalFetchDriver.type.REQUEST, onFetchDriver)
  yield takeEvery(ContractorPortalSetDriver.type.SUCCESS, onSetDriverSuccess)
}
